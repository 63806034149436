import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import Popup from '../components/Popup';
import React, { useState } from 'react';
import { SimulatorPage } from "../pages/SimulatorEntry";
import ContextList from '../components/ContextList';


import styled from 'styled-components';

const StyledGrid = styled(IonGrid) <ContainerProps>`
	position: absolute;
	z-index: 9;
	width: 291px;
	height: 469px;
	border-radius: 4px;
	padding: 0;
	padding-top: 39px;
	overflow: hidden;
`;

const StyledCol = styled(IonCol) <ContainerProps>`
	text-align: ${props => props.textAlign};
	background: ${props => props.bg || '#FFFFFF'};
	border-bottom: ${props => props.borderBottom};
	border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
	border-bottom-right-radius: ${props => props.borderBottomRightRadius};
	margin: ${props => props.margin};
	padding: ${props => props.padding};
	height:  ${props => props.height};
`;

const StyledButton = styled(IonButton) <ContainerProps>`
	height: auto;
	padding: 0;
	--padding-start: 0;
	--padding-end: 0;
	margin: 0;
	--background-activated: transparent;
`;


const StyledPText = styled.p<ContainerProps>`
	font-family: "Open Sans";
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
	margin: ${props => props.Margin};
`;

interface ContainerProps {
	textAlign?: string;
	bg?: string;
	borderBottom?: string;
	borderBottomLeftRadius?: string;
	borderBottomRightRadius?: string;
	margin?: string;
	padding?: string;
	height?: string;
	routerLink?: string;
	fontWeight?: string;
	fontSize?: string;
	Margin?: string;
	setCurrentPage?: (page: SimulatorPage) => void;
	setIsMenuShown?: Function;
	hideMenuItems?: Boolean;
	hideAlertItems?: Boolean;
	hideBorderBottom?: Boolean;
}

const Menu: React.FC<ContainerProps> = ({ children, setCurrentPage, setIsMenuShown, hideMenuItems, hideAlertItems, hideBorderBottom }) => {

	const [showSoundPopup, setShowSoundPopup] = useState(false);
	const [showGlucosePopup, setShowGlucosePopup] = useState(false);
	const [showDevicePopup, setShowDevicePopup] = useState(false);
	const [showAlertPopup, setShowAlertPopup] = useState(false);

	const contentsData = [
		{
			label: "Insulin Fill Cannula",
			subLabel: "2020-Mar-31, 12:00 pm",
			icon: "assets/icon/icon-warning-square.png",
			popup: 'setShowAlertPopup',
		},
		{
			label: "Insulin Low",
			subLabel: "2020-Mar-31, 12:00 pm",
			popup: 'setShowAlertPopup',
			icon: "assets/icon/icon-warning-square.png",
			borderBottom: true,
		},
	]

	return (
		<>
		

			<StyledGrid className="menu">

				<Popup
					show={showAlertPopup}
					setShow={setShowAlertPopup}
					center={true}
				>
					<p>Get up to speed with a<br/>summary of your most recent<br/>alerts.</p>
				</Popup>

				<Popup
					show={showSoundPopup}
					setShow={setShowSoundPopup}
					center={true}
				>
					<p>You can adjust your iLet<sup>&reg;</sup> alert volume! Remember, your iLet<sup>&reg;</sup> will always sound with any urgent alerts.</p>
				</Popup>

				<Popup
					show={showGlucosePopup}
					setShow={setShowGlucosePopup}
					center={true}
				>
					<p>This is where you'll enter a blood glucose reading and calibrate your sensor.</p>
				</Popup>

				<Popup
					show={showDevicePopup}
					setShow={setShowDevicePopup}
					center={true}
				>	
					<p>
						Here’s where you will pair your iLet<sup>&reg;</sup> app and iLet<sup>&reg;</sup>. This lets you upload your iLet<sup>&reg;</sup> device data to the cloud and download new software updates when they’re available.
					</p>
				</Popup>


				<IonRow className="ion-text-center">
					
					<StyledCol
						textAlign={!hideMenuItems ? 'initial' : 'end'} 
						bg="transparent"
						borderBottom={!hideBorderBottom ? '2px solid white' : 'none'}
						padding="0"
						height="74px"
						size="12">
						{children}
					</StyledCol>

					{!hideMenuItems &&
						<>
							<StyledCol bg="black" size="12">
								<StyledPText fontSize="24px" fontWeight="400" Margin="8px 0 16px">Menu</StyledPText>
							</StyledCol>
							<StyledCol onClick={(event: React.MouseEvent<HTMLElement>) => {
								setShowGlucosePopup(true)
							}} bg="black" size="4" padding="0 0 24px">
								<img src="../assets/icon/icon-blood-glucose.png" width="auto" height="70px" alt="" />
							</StyledCol>
							<StyledCol bg="black" size="4" padding="0 0 24px">
								<img
								onClick={() => {
										setCurrentPage?.("PauseInsulin");
										setIsMenuShown?.(false);
									}
								}	
								src="../assets/icon/Button_Pause_Insulin_96x96.png"
								width="90px"
								height="auto"
								style={{ position:"relative", bottom:"10px" }}
								alt="" />

							</StyledCol>
							<StyledCol bg="black" size="4" padding="0 0 0 0px">
								<img
								onClick={() => {
										setCurrentPage?.("CGM");
										setIsMenuShown?.(false);
									}
								}	
								src="../assets/icon/icon-cgm.png"
								width="70px"
								height="auto"
								alt="" />
							</StyledCol>
							<StyledCol onClick={(event: React.MouseEvent<HTMLElement>) => {
								setShowDevicePopup(true)
							}} bg="black" size="4" padding="0 0 24px">
								<img src="../assets/icon/icon-ilet-device.png" width="auto" height="70px" alt="" />
							</StyledCol>
							<StyledCol bg="black" size="4" padding="0 0 24px">
								<img
									onClick={() => {
											setCurrentPage?.("settings");
											setIsMenuShown?.(false);
										}
									}
									src="../assets/icon/icon-settings.png"
									width="70px"
									height="auto"
									alt=""
									/>
							</StyledCol>
							<StyledCol bg="black" size="4" padding="0 0 24px">
								<img
									onClick={() => {
										setCurrentPage?.("history");
										setIsMenuShown?.(false);
										}
									}
									src="../assets/icon/icon-history.png"
									width="70px"
									height="auto"
									alt=""
									/>
							</StyledCol>
							<StyledCol onClick={(event: React.MouseEvent<HTMLElement>) => {
								setShowSoundPopup(true)
							}} bg="black" size="4">
								<img src="../assets/icon/icon-sound.png" width="70px" height="auto" alt="" />
							</StyledCol>
							<StyledCol bg="black" size="4">
							</StyledCol>
							<StyledCol
								borderBottomLeftRadius="4px"
								borderBottomRightRadius="4px"
								height="182px" bg="black" size="12">
							</StyledCol>
						</>
					}

					{!hideAlertItems &&
						<>
							<StyledCol padding="0" bg="black" size="12">
								<StyledPText Margin="12px 0 16px" fontSize="24px" fontWeight="400">Notifications</StyledPText>
								<ContextList
									hideAlertItems={!hideAlertItems}
									setShowAlertPopup={setShowAlertPopup}
									marginTop="0"
									margin="0"
									data={contentsData}
									padding="0"
									fontSize="24px"
									borderTop='2px solid white'
									borderBottom='2px solid white'
									setCurrentPage={setCurrentPage}
								/>
							</StyledCol>
							
							<StyledCol
								borderBottomLeftRadius="4px"
								borderBottomRightRadius="4px"
								height="152px" bg="black" size="12">
							</StyledCol>
							
						</>
					}
					
				</IonRow>
			</StyledGrid>
		</>
	);
};

export default Menu;
