import { IonRow, IonText } from '@ionic/react';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
const StyledRow = styled(IonRow)`
	padding: 5px 7px;
`;

const StyledTime = styled(IonText)`
	font-family: "Open Sans";
	font-size: 20px;
	margin-right: auto;
`;

const StyledP = styled.p`
	margin: 0;
	line-height: 0.7;
`;

const StyledImg = styled.img`
	margin-right: 6px;
`;

interface ContainerProps { 
	showBellIcon?: Boolean
	showStatusIcon?: Boolean
	showCGMIcon?: Boolean
}

const StatusBar: React.FC<ContainerProps> = ({showBellIcon, showStatusIcon, showCGMIcon}, props) => {

	const [currentTime, setCurrentTime] = useState(getCurrentTime());

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(getCurrentTime())
		}, 1000);
		return () => clearInterval(interval);
	}, [])

	function getCurrentTime() {
		const time = new Date();
		return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
	}

	return (
		<StyledRow className="ion-justify-content-end ion-align-items-start ion-padding-horizontal-md">
			<StyledTime className="time">
				<StyledP>{currentTime}</StyledP>
			</StyledTime>
			{
				showBellIcon &&
				<StyledImg src="../assets/icon/icon-bell-status.png" width="16px" height="auto" alt="" />
			}
			{
				showStatusIcon &&
				<StyledImg className="icon-status" src="../assets/icon/icon-status.png" width="22px" height="auto" alt="" />
			}
			{
				showCGMIcon &&
				<StyledImg src="../assets/icon/icon_cgm_status.png" width="24px" height="auto" alt="" />
			}

			
			<StyledImg className="icon-battery" src="../assets/icon/icon-battery.png" width="42px" height="auto" alt="" />

		</StyledRow>
	);
};

export default StatusBar;
