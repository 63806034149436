import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";

const DropdownWrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  padding: 0 10px;
  margin: 0 0 10px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 1.625rem;
  background-color: #000;
  .dropdown-label {
    width: 100%;
    height: 84px;
    line-height: 80px;
    padding: 0 20px;
    border-radius: 25px;
    border-bottom-left-radius: ${(props) => (props.isOpen ? "0" : "25px")};
    border-bottom-right-radius: ${(props) => (props.isOpen ? "0" : "25px")};
    border: 2px solid white;
    display: flex;
    background-color: ${(props) => (props.isOpen ? "white" : "black")};
    color: ${(props) => (props.isOpen ? "black" : "white")};
    justify-content: space-between;
    .up-arrow {
      filter: invert(1);
      transform: rotate(90deg);
      height: 20px;
      margin-top: 4px;
    }
    .down-arrow {
      transform: rotate(-90deg);
      height: 20px;
      margin-top: 4px;
    }
  }
  .dropdown-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 0.15rem solid white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    .item {
      width: 100%;
      height: 84px;
      line-height: 80px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

interface DropdownProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  label: any;
  items: { id: string; label: string; icon?: string }[];
  onSelect: (item: { id: string; label: string; icon?: string }, setIsOpen: Dispatch<SetStateAction<boolean>> ) => void;
  selectIsOpen?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  open,
  setOpen,
  label,
  items,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!open);
  }, [open]);

  return (
    <DropdownWrapper isOpen={isOpen} >
      <div
        onClick={() => {
          setOpen ? setOpen(!isOpen) : setIsOpen(!isOpen);
        }}
        className="dropdown-label"
      >
        <div>{label}</div>
        <div>
          {isOpen ? (
            <img
              className="up-arrow"
              src="assets/icon/back-arrow.png"
              alt=""
            />
          ) : (
            <img
              className="down-arrow"
              src="assets/icon/back-arrow.png"
              alt=""
            />
          )}
        </div>
      </div>
      {isOpen ? (
        <>
          <div className="dropdown-items">
            {items.map((item: any, i: any ) => (
              <div key={i}>
                <div
                  key={item.id}
                  onClick={() => onSelect({ ...item }, setIsOpen)}
                  className="item"
                >
                  <div>{item.label}</div>
                  <img src={item.icon} height="44px" width="auto" alt="" />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </DropdownWrapper>
  );
};

export default Dropdown;

/* Example usage:
<Dropdown
  label="Example"
  onSelect={(item) => {
    alert(JSON.stringify(item));
  }}
  items={[
    {
      id: 'example1',
      label: "Example 1",
      icon: "assets/icon/calendar.png",
    },
  ]}
/>
*/
