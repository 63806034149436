import styled from "styled-components";
import { IonRow } from "@ionic/react";
import React, { useState } from "react";
import { SimulatorPage } from "../pages/SimulatorEntry";

const StyledNavBar = styled(IonRow)`
  margin: 8px 0;
  padding: 3px 16px;
`;

const StyledTimeRange = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid white;
  border-radius: 30px;
  padding: 0.75rem 1rem;
  width: 160px;
  p {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 auto;
  }
`;

const StyledCalendar = styled.img`
  width: 100%;
`;

const CalendarWrapper = styled.div`
  position: relative;
  .top-half {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }
  .bottom-half {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
  }
`;

const graphRanges = [
  {
    text: "3 Hrs",
    image: "assets/calendar/3-hours.png",
    insulinImage: "assets/calendar/3-hours-insulin.png",
    cgmImage: "assets/calendar/3-hours-cgm.png",
  },
  {
    text: "6 Hrs",
    image: "assets/calendar/6-hours.png",
    insulinImage: "assets/calendar/6-hours-insulin.png",
    cgmImage: "assets/calendar/6-hours-cgm.png",
  },
  {
    text: "12 Hrs",
    image: "assets/calendar/12-hours.png",
    insulinImage: "assets/calendar/12-hours-insulin.png",
    cgmImage: "assets/calendar/12-hours-cgm.png",
  },
  {
    text: "24 Hrs",
    image: "assets/calendar/24-hours.png",
    insulinImage: "assets/calendar/24-hours-insulin.png",
    cgmImage: "assets/calendar/24-hours-cgm.png",
  },
];

const summaryRanges = [
   {
    text: "1 Day",
    image: "assets/calendar/1-day-daily-summary.png",
   },
   {
    text: "7 Days",
    image: "assets/calendar/7-day-daily-summary.png",
   },
   {
    text: "30 Days",
    image: "assets/calendar/30-day-daily-summary.png",
   },
   {
    text: "90 Days",
    image: "assets/calendar/90-day-daily-summary.png",
   }
]

interface CalendarProps {
  setCurrentPage: (page: SimulatorPage) => void;
} 

const Calendar: React.FC<CalendarProps> = ({ setCurrentPage }) => {
  const [currentRange, setCurrentRange] = useState(0);
  const [currentZoom, setCurrentZoom] = useState<"all" | "insulin" | "cgm">("all");
  const [currentView, setCurrentView] = useState<"graph" | "summary">("graph");

  const handleBack = () => {
    if (currentView === "graph") {
      setCurrentRange(currentRange === 0 ? graphRanges.length - 1 : currentRange - 1);
    } else {
      setCurrentRange(currentRange === 0 ? summaryRanges.length - 1 : currentRange - 1);
    }
  };

  const handleNext = () => {
    if (currentView === "graph") {
      setCurrentRange(currentRange === graphRanges.length - 1 ? 0 : currentRange + 1);
    } else {
      setCurrentRange(currentRange === summaryRanges.length - 1 ? 0 : currentRange + 1);
    }
  };

  const handleZoom = (zoom: "insulin" | "cgm") => {
    setCurrentZoom(currentZoom === "all" ? zoom : "all");
  };

  return (
    <>
      <StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
        <img
          onClick={() => {setCurrentPage("entry")}}
          src={"assets/icon/back-arrow.png"}
          width="auto"
          height="34px"
          alt=""
        />
        <StyledTimeRange className="time-range">
          <img
            onClick={handleBack}
            src={"assets/icon/back-arrow.png"}
            width="auto"
            height="22px"
            alt=""
          />
          <p>{currentView === "graph" ? graphRanges[currentRange].text : summaryRanges[currentRange].text}</p>
          <img
            onClick={handleNext}
            src={"assets/icon/back-arrow.png"}
            width="auto"
            height="22px"
            style={{ transform: "rotate(180deg)" }}
            alt=""
          />
        </StyledTimeRange>
        <img
          src={currentView === "graph" ? "assets/icon/calendar.png" : "assets/icon/icon-graph.png"}
          onClick={() => {
            setCurrentRange(0)
            setCurrentZoom('all')
            setCurrentView(currentView === "graph" ? "summary" : "graph")
          }}
          width="40px"
          height="auto"
          alt=""
        />
      </StyledNavBar>
      <CalendarWrapper style={{ position: "relative" }}>
        <StyledCalendar
          src={
            currentView === "summary" ? summaryRanges[currentRange].image :
            currentZoom === "all"
              ? graphRanges[currentRange].image
              : currentZoom === "insulin"
              ? graphRanges[currentRange].insulinImage
              : graphRanges[currentRange].cgmImage
          }
        ></StyledCalendar>
        <div onClick={() => handleZoom("cgm")} className="top-half"></div>
        <div
          onClick={() => handleZoom("insulin")}
          className="bottom-half"
        ></div>
      </CalendarWrapper>
    </>
  );
};

export default Calendar;
