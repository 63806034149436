import React, { useState, useEffect } from 'react';
import { Device } from '@capacitor/device';
import styled from "styled-components";
import Button from './Button';
import { useTransition, animated } from '@react-spring/web';

interface PopupProps {
	show?: boolean;
	setShow?: (show: boolean) => void;
	children?: React.ReactNode;
	center?: boolean;
	backgroundColor?: string;
}

const PopupWrapper = styled.div<PopupProps>`
	position: absolute;
	display: flex;
	width: 291px;
	height: 430px;
	justify-content: center;
	align-items: center;
	z-index: 99999;
	background-color: ${props => props.backgroundColor};
`;
const PopupInner = styled.div`
	background-color: white;
	height: 430px;
	border-radius: 3rem;
`;

const PopupContent = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	padding: 2rem;
	flex-direction: column;
	justify-content: space-between;
`;

const PopupCenter = styled.div`
	width: 100%;
	height: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	text-align: center;
	color: #000000;
`;




const Popup: React.FC<PopupProps> = ({ children, show, setShow, center }) => {

	const [device, setDevice] = useState<string>();
	const [popupBackgroundColour, setPopupBackgroundColour] = useState('');
	
	const platform = async () => {
		const info = await Device.getInfo();
		return info.platform;
	};

	
	
	useEffect(() => {
		platform()
		.then((pl) => { setDevice(pl) })
		.catch((err) => { return err });
		
		show ? setPopupBackgroundColour('rgba(0,0,0,0.5)') : setPopupBackgroundColour('rgba(0,0,0,0)');
	}, [show])

	let colorSet: string = "";
	switch(device){
		case "web":
			colorSet = "dark";
			break;
		case "ios":
			colorSet = "dark";
			break;
		case "android":
			colorSet = "light";
			break;
	};

	const transition = useTransition(show, {
		from: {x:0, y:800, opacity: 0,},
		enter: {x:0, y:0, opacity: 1,},
		leave: {x:0, y:800, opacity: 0,},
	});





	return (
		<>
			
			{transition((style, item) => {
				return item ?
					(
					
						<PopupWrapper backgroundColor={popupBackgroundColour}>
							<animated.div className={'animatedDivContainer'} style={style}>
							<PopupInner>
								<PopupContent>
									{center ? <PopupCenter>{children}</PopupCenter> : children}
									<Button
										label="Close"
										color={colorSet}
										onClick={() => {
											setShow?.(false)
											setPopupBackgroundColour('rgba(0,0,0,0)');
											

										
										}} />
								</PopupContent>
							</PopupInner>
							</animated.div>
						</PopupWrapper>
						
					
					) : null;
					
				
			})}
		</>
	)
}

export default Popup;