import { IonCol, IonText, IonRow } from "@ionic/react";
import StatusBar from "../components/StatusBar";
import Menu from "../components/Menu";
import ILetFrame from "../components/ILetFrame";
import Slider from '../components/Slider';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Calendar from "../components/Calendar";
import Settings from "../components/Settings";
import History from "../components/History";
import BackupTherapy from "../components/BackupTherapy";
import CGM from "../components/CGM";
import SwitchCGMSensor from "../components/SwitchCGMSensor"; // New screen added for switch cgm sensor
import PauseInsulin from "../components/PauseInsulin"; // New screen added for Pause Insulin
import Meals from "../components/Meals";
import Popup from '../components/Popup';
import DeliverPrep from '../components/DeliverPrep';
import Starting from '../components/Starting';
import Delivering from '../components/Delivering';
import AlgorithmSteps from "../components/AlgorithmSteps";

const StyledGoBionic = styled(IonRow)<EntryProps>`
	height: 230px;
	margin: 24px auto 4px auto;
	max-width: 230px;
	background-image: url(../assets/global/go-bionic-circle.png);
	background-size: 100%;
	background-repeat: no-repeat;
	overflow: hidden;
	transform: rotate(${(props) => props.deg ? props.deg * 10 : null }deg);
    transition: transform 1s linear;
`;
const StyledNavBar = styled(IonRow)`
	margin-top: 8px;
	padding: 3px 16px;
`;

const StyledImg = styled.img`
	margin-top: 16px;
`;

const StyledPText = styled.p<EntryProps>`
	font-family: "Open Sans";
	font-weight: ${(props) => props.fontWeight};
	font-size: ${(props) => props.fontSize};
	margin: ${(props) => props.Margin};
`;

const HoldingStyle = styled.div`
    position: relative;

    .ion-text-center {
        position: absolute;
        top:52px;
    }
`;
interface EntryProps {
	currentPage?: string;
	setCurrentPage?: (page: SimulatorPage) => void;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
	setIsMenuShown?: Function,
	counter?: Number,
	deg?: number,
}

export type SimulatorPage = 
"entry" 
| "calender" 
| "settings" 
| "history" 
| "backupTherapy" 
| "switchCGMSensor" // New screen added for switch cgm sensor
| "PauseInsulin" // New screen added for pause insulin
| "CGM" 
| "Meals" 
| "DeliverPrep" 
| "Starting" 
| "Delivering"
| "algorithmSteps";

const SimulatorEntry: React.FC<EntryProps> = () => {

	const [currentPage, setCurrentPage] = useState<SimulatorPage>("entry");

	const [isMenuShown, setIsMenuShown] = useState(false);
	const [showCartridgePopup, setShowCartridgePopup] = useState(false);
	const [unlocked, setUnlocked] = useState(false);
	const [hideMenuItems, setHideMenuItems] = useState(false);
	const [hideAlertItems, setHideAlertItems] = useState(false);
	const [hideStatusBarBellIcon, setHideStatusBarBellIcon] = useState(true);
	const [hideStatusBarCGMIcon, setHideStatusBarCGMIcon] = useState(true);
	
	const [deg, setDeg] = useState(0);
	let [prevPage, setPrevPage] = useState<Array<String>>(['entry']);

	useEffect(() => {
		if (currentPage === 'entry'){
			//eslint-disable-next-line
			prevPage = [...new Set(prevPage)];
		}
		prevPage.push(currentPage)
		if((prevPage.length > 0 && prevPage[2] === 'DeliverPrep') || (prevPage.length > 0 && prevPage[3] === 'Starting')){
			setUnlocked(false);	
		}
		if(currentPage === 'entry' && prevPage.length > 3){
			setPrevPage(['entry'])
		}
	},[currentPage])

	useEffect(()=> {;
		if(currentPage === 'entry'){
			setTimeout(() => {
				setDeg(deg + 5);
			}, 1000);
			return () => clearTimeout();
		}
		return () => clearTimeout();
	},[deg, currentPage])

	return (

		<>
			<ILetFrame>
				
				{isMenuShown && (
					<Menu hideMenuItems={hideMenuItems} hideAlertItems={hideAlertItems} setIsMenuShown={setIsMenuShown} setCurrentPage={setCurrentPage}>
						
						<img
							onClick={
								() => { 
									setIsMenuShown(false)
									setHideStatusBarBellIcon(true)
									setHideStatusBarCGMIcon(true)
								}
							}
							src="../assets/icon/icon-close-menu.png"
							width="82px"
							height="auto"
							alt=""
						/>
					</Menu>
				)}

				<StatusBar showBellIcon={hideStatusBarBellIcon} showCGMIcon={hideStatusBarCGMIcon}></StatusBar>

				<Popup
					show={showCartridgePopup}
					setShow={setShowCartridgePopup}
					center={true}
				>
					<p>Keep track of your<br/> remaining units of insulin. <br/><br/> You can change<br/>your infusion set and cartridge here too!</p>
				</Popup>

				{currentPage === "entry" ? (
					<>
						<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
							
							{
								unlocked ? 
								<img
									onClick={
										() => { setIsMenuShown(true)
												setHideMenuItems(false)
												setHideAlertItems(true)
												setHideStatusBarBellIcon(true)
											}
									}
									className="icon-menu"
									src="../assets/icon/icon-menu.png"
									width="44px"
									height="auto"
									alt=""
								/>
								:
								<img
									
									className="icon-menu"
									src="../assets/icon/icon-lock.png"
									width="44px"
									height="auto"
									alt=""
								/>
							}
							<img
							
								onClick={(event: React.MouseEvent<HTMLElement>) => {
										
									unlocked &&
									setShowCartridgePopup(true)
								}}
								className="icon-cartridge"
								src="../assets/icon/icon-cartridge.png"
								width="24px"
								height="auto"
								alt=""
							/>
							<img
							
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									
									unlocked &&
									setIsMenuShown(true)
									setHideMenuItems(true)
									setHideAlertItems(false)
									unlocked &&
									setHideStatusBarBellIcon(true)
									setHideStatusBarCGMIcon(true)
									
									
								}}
								className="icon-bell"
								src={`../assets/icon/icon-bell${unlocked ? `-notification-2` : ""}.png`}
								width="48px"
								height="auto"
								alt=""
							/>
						</StyledNavBar>

						<HoldingStyle onClick={() => {unlocked && setCurrentPage("calender")}}>
                            <StyledGoBionic deg={deg} class="text-center ion-align-items-center go-bionic"
								 
							/>
							<IonCol size="12" className="ion-text-center">
								<img
									className="icon-arrow-right"
									src="../assets/icon/icon-arrow-right.png"
									width="36px"
									height="auto"
									alt=""
								/>
								<IonText>
									<StyledPText
										fontWeight="600"
										fontSize="36px"
										Margin="4px 0"
										className="mg-value"
									>
										115
									</StyledPText>
								</IonText>
								<IonText>
									<StyledPText fontSize="20px" Margin="0">mg/dL</StyledPText>
								</IonText>
							</IonCol>
                        </HoldingStyle>

						<IonRow>
							{
							!unlocked ?
							<IonCol size="12" className="ion-text-center">
								<Slider margin="12px 0 0 0" label="Unlock" onSlide={() => { setUnlocked(true) }} />
							</IonCol>
							:
							<IonCol size="12" className="ion-text-center">
								<StyledImg
								onClick={
									() => { setCurrentPage?.("Meals") }
								}
								className="icon-meal"
								src="../assets/icon/icon-meal.png"
								width="72px"
								height="auto"
								alt=""
							/>
							</IonCol>
							}
							
						</IonRow>
					</>
				) 
				
				: currentPage === "calender" ? (
					<Calendar setCurrentPage={setCurrentPage} />
				)

				: currentPage === "settings" ? (
					<Settings setIsMenuShown={setIsMenuShown} setCurrentPage={setCurrentPage} />
				) 
				
				: currentPage === "history" ? (
					<History setIsMenuShown={setIsMenuShown} setCurrentPage={setCurrentPage} />
				)

				: currentPage === "backupTherapy" ? (
					<BackupTherapy setCurrentPage={setCurrentPage} />
				)

				: currentPage === "CGM" ? (
					<CGM setIsMenuShown={setIsMenuShown} setCurrentPage={setCurrentPage} />
				)
				
				//New screen added for pause insulin
				: currentPage === "PauseInsulin" ? (
					<PauseInsulin setIsMenuShown={setIsMenuShown} setCurrentPage={setCurrentPage} />
				)

				//New screen added for switch cgm sensor
				: currentPage === "switchCGMSensor" ? (
					<SwitchCGMSensor setCurrentPage={setCurrentPage} />
				)

				: currentPage === "Meals" ? (
					<Meals setIsMenuShown={setIsMenuShown} currentPage={currentPage} setCurrentPage={setCurrentPage} />
				)

				: currentPage === "DeliverPrep" ? (
					<DeliverPrep 
					setIsMenuShown={setIsMenuShown} 
					currentPage={currentPage} 
					setCurrentPage={setCurrentPage} 
					/>
				)

				: currentPage === "Starting" ? (
					<Starting 
					setIsMenuShown={setIsMenuShown} 
					currentPage={currentPage} 
					setCurrentPage={setCurrentPage} 
					/>
				)

				: currentPage === "Delivering" ? (
					<Delivering 
					setIsMenuShown={setIsMenuShown} 
					currentPage={currentPage} 
					setCurrentPage={setCurrentPage} 
					/>
				)

				: currentPage === "algorithmSteps" ? (
					<AlgorithmSteps setCurrentPage={setCurrentPage} />
				)

				:null

				}
			</ILetFrame>
		</>
	);
};

export default SimulatorEntry;
