import React from 'react';
import styled from "styled-components";
import { IonRow } from "@ionic/react";
import { SimulatorPage } from "../pages/SimulatorEntry";

const StyledNavBar = styled(IonRow)`
  margin-top: 0;
  padding: 3px 16px;
`;

const StyledPText = styled.p<SettingsProps>`
	font-family: "Open Sans";
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
	margin: ${props => props.Margin};
`;

interface SettingsProps {
	setCurrentPage?: (page: SimulatorPage) => void;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
}

const BackupTherapy: React.FC<SettingsProps> = ({ setCurrentPage }) => {

	return (
		<>
			<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
				<img
					onClick={() => { setCurrentPage?.("history") }}
					src={"assets/icon/back-arrow.png"}
					width="auto"
					height="34px"
					alt=""
				/>

				<StyledPText
					fontSize="22px"
				>Insulin History
				</StyledPText>

				<img
					onClick={() => { setCurrentPage?.("entry") }}
					className="icon-home"
					src={"assets/icon/icon-home.png"}
					width="40px"
					height="auto"
					alt=""
				/>
			</StyledNavBar>

			<div className="list">
				<div className="item">
					<div className="key">Total Daily Basal</div>
					<div className="value">9.0 units</div>
				</div>
				<div className="item">
					<div className="key">Breakfast (Usual for me)</div>
					<div className="value">5.0 units</div>
				</div>
				<div className="item">
					<div className="key">Lunch (Usual for me)</div>
					<div className="value">4.6 units</div>
				</div>
				<div className="item">
					<div className="key">Dinner (Usual for me)</div>
					<div className="value">6.8 units</div>
				</div>
			</div>
		</>
	);
};

export default BackupTherapy;
