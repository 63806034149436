import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import styled from 'styled-components';

const StyledButton = styled(IonButton) <ContainerProps>`
	position: static;
	z-index: 99;
	--border-radius: 30px;
	--padding-start: 30px;
	--padding-end: 30px;
	--color: ${props => props.color ? props.color : 'white'};
	text-transform: inherit;
	font-family: 'Open Sans';
	font-weight: 700; 
	font-size: ${props => props.fontSize};
	width: ${props => props.width};
	height: ${props => props.height || '52px'};
	max-width: ${props => props.maxWidth};
	margin: ${props => props.margin};
	padding: ${props => props.padding};

	.button-native {
		margin-left: 3px;
	}
	
	&.addAnimation {
		&::after {
			content: '';
			clear: both;
			height: 101px;
			width: 293px;
			position: relative;
			top: -76px;
			left: -24px;
			display: block;
			background-image: url(assets/icon/pulse-animation-wide.gif);
			background-size: 100%;
			z-index: 9;
			background-repeat: no-repeat;
		}
	}
	
}
`;

interface ContainerProps {
	// Button label
	label?: string,
	// Button href
	href?: string,
	// Button palette (optional, defaults to primary)
	color?: string,
	// Button fill (optional, defaults to solid)
	fill?: 'solid' | 'clear' | 'outline',
	// Button icon (optional)
	icon?: string,
	// Button onClick event (optional)
	onClick?: Function,

	fontSize?: string,
	width?: string,
	height?: string,
	maxWidth?: string,
	margin?: string,
	padding?: string,
	direction?: any,
	className?: string,
};

const Button: React.FC<ContainerProps> = ({
	fontSize,
	width,
	height,
	maxWidth,
	margin,
	padding,
	direction,
	label,
	href,
	color = 'primary',
	fill = 'solid',
	icon,
	onClick,
	className
}) => {
	return (
		<StyledButton
			color={color}
			fontSize={fontSize}
			width={width}
			height={height}
			maxWidth={maxWidth}
			margin={margin}
			padding={padding}
			fill={fill}
			expand="block"
			className={className}
			routerLink={href}
			routerDirection={href ? direction : undefined}
			onClick={onClick ? () => onClick() : undefined}
		>
			{label}
			{icon && <IonIcon slot="end" icon={icon} />}
		</StyledButton>
	);
};

export default Button;
