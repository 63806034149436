import { IonRow, IonCol, IonText } from "@ionic/react";
import StatusBar from "../components/StatusBar";
import Menu from "../components/Menu";
import { Device } from '@capacitor/device';
import ILetFrame from "../components/ILetFrame";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from '../components/Button';
import PopupBottom from '../components/PopupBottom';

const StyledNavBar = styled(IonRow)`
  margin-top: 8px;
  padding: 3px 16px;
`;

const StyledPText = styled.p<ContainerProps>`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.Margin};
  text-align: ${(props) => props.textAlign};
`;

const StyledRow = styled(IonRow)<ContainerProps>`
  padding: ${props => props.padding};
`;

const StyledGoBionic = styled(IonRow)`
	position: relative;
	height: 240px;
	margin: 30px auto 0 auto;
	max-width: 230px;
	background-image: url(../assets/global/go-bionic-circle.png);
	background-size: 100%;
	background-repeat: no-repeat;
	overflow: hidden;
`;

const StyledImg = styled.img`
  margin-top: 16px;
`;

interface ContainerProps {
	fontWeight?: string;
	fontSize?: string;
	Margin?: string;
	padding?: string;
	height?: string;
	textAlign?: string;
};

export type HighGlucosePage = "highGlucoseContent" | "goBionic";

const HighGlucose: React.FC<ContainerProps> = ({padding, height, textAlign}, props) => {
	const [currentPage, setCurrentPage] = useState<HighGlucosePage>("highGlucoseContent");
	const [hideCloseMenuButton, setHideCloseMenuButton] = useState(false);
	const [hideStatusBarBellIcon, setHideStatusBarBellIcon] = useState(true);
	const [hideBorderBottom, setHideBorderBottom] = useState(false);
	const [showScenariosPopup, setShowScenariosPopup] = useState(false);
	const [device, setDevice] = useState<string>();

	const platform = async () => {
		const info = await Device.getInfo();
		return info.platform;
	};
	
	useEffect(() => {
		platform()
		.then((pl) => { setDevice(pl) })
		.catch((err) => { return err });
	})

	let colorSet: string = "";
	switch(device){
		case "web":
			colorSet = "light";
			break;
		case "ios":
			colorSet = "light";
			break;
		case "android":
			colorSet = "dark";
			break;
	};

	return (

		<>
			<ILetFrame>
				
			
				<Menu hideMenuItems={true} hideAlertItems={true} hideBorderBottom={hideBorderBottom}>
					{ !hideCloseMenuButton && 
						<img
							
							src="../assets/icon/icon-close-menu.png"
							width="82px"
							height="auto"
							alt=""
						/>
					}
				</Menu>
		

				<StatusBar showBellIcon={hideStatusBarBellIcon}></StatusBar>

				<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
					
					
					<img
						
						className="icon-menu"
						src="../assets/icon/icon-menu.png"
						width="44px"
						height="auto"
						alt=""
					/>
						
					
					<img
						className="icon-cartridge"
						src="../assets/icon/icon-cartridge.png"
						width="25px"
						height="auto"
						alt=""
					/>
					<img
					
					
						className="icon-bell"
						src="../assets/icon/icon-bell.png"
						width="48px"
						height="auto"
						alt=""
					/>
				</StyledNavBar>

				

				{currentPage === "highGlucoseContent" ? (
					<>
						<StyledRow padding="20px 0 0 16px" className="ion-justify-content-start">
							<img
								src="../assets/icon/icon-arrow-back-small.png"
								width="16px"
								height="26px"
								alt=""
							/>
						</StyledRow>
						<StyledRow padding="20px 0 0 16px" className="ion-justify-content-center">
							<img
								src="../assets/icon/high-glucose.png"
								width="100px"
								height="60px"
								alt=""
							/>
						</StyledRow>

						<IonRow className="ion-justify-content-center">
							<StyledPText Margin="16px 0 10px 0" fontSize="24px"><strong>High Glucose</strong></StyledPText>
							<StyledPText textAlign="center" Margin="0 0 30px 0" fontSize="18px">Glucose has been above<br/>300 mg/dL for more than<br/>90 minutes.</StyledPText>
						</IonRow>

						<StyledRow height="176px" className="ion-justify-content-center">
							<Button
								label="Dismiss"
								color={colorSet}
								fill="outline"
								fontSize="20px"
								width="100%"
								padding="0 21px"
								className="addAnimation"
								onClick={() => {
									setHideCloseMenuButton(true);
									setHideBorderBottom(true);
									setHideStatusBarBellIcon(false);
									setCurrentPage('goBionic');
									setTimeout(() => {
										setShowScenariosPopup(true);
									}, 2000);
								}}
							/>
						</StyledRow>
					</>
				) 

				: currentPage === "goBionic" ? (
					<>
					<StyledGoBionic
							
							class="text-center ion-align-items-center go-bionic"
						>
							<IonCol size="12" className="ion-text-center">
								<img
									className="icon-arrow-right-up"
									src="../assets/icon/icon-arrow-right-up.png"
									width="26px"
									height="auto"
									alt=""
								/>
								<IonText>
									<StyledPText
										fontWeight="600"
										fontSize="36px"
										Margin="4px 0"
										className="mg-value"
									>
										357
									</StyledPText>
								</IonText>
								<IonText>
									<StyledPText fontSize="20px" Margin="0">mg/dL</StyledPText>
								</IonText>
							</IonCol>
						</StyledGoBionic>

						<IonRow>
						
							<IonCol size="12" className="ion-text-center">
								<StyledImg
								
								className="icon-meal"
								src="../assets/icon/icon-meal.png"
								width="60px"
								height="auto"
								alt=""
							/>
							</IonCol>
							
							
						</IonRow>

						<PopupBottom
							show={showScenariosPopup}
							setShow={setShowScenariosPopup}
							center={true}
							label="Back to Scenarios"
							href="Scenarios"
							button={true}
							backgroundOverlay={true}
						>
							<p>Head back to explore some<br/>more scenarios.</p>
						</PopupBottom>
					</>
				)
			
				: null}
			</ILetFrame>

			<p>test</p>
		</>
	);
};

export default HighGlucose;
