import React from 'react';
import styled from 'styled-components';
import { IonToolbar } from '@ionic/react';

const StyledToolbar = styled(IonToolbar)<ContainerProps>`
  --background: ${props => props.bg || '#ffffff'};
`;

interface ContainerProps {
  bg?: string,
  children: React.ReactNode,
 }

const Toolbar: React.FC<ContainerProps> = ( props ) => {
  return (
    <StyledToolbar {...props}>
   
    </StyledToolbar>
  );
};

export default Toolbar;
