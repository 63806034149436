import { useDrag } from "@use-gesture/react";
import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

const SliderWrapper = styled.div<SliderProps>`
	position: ${props => props.position || 'relative'};
  top: ${props => props.top || '0'};
	left:  ${props => props.left || '0'};
	display: flex;
	align-items: center;
	background-color: black;
	border: 2px solid white;
	border-radius: 50px;
	transform: translateZ(0);
	width: ${props => props.width || '100%'};
	height: ${props => props.height || '68px'};
	margin: ${props => props.margin || '20px 0 0 0'};
	overflow: hidden;
	color: white;
`;

const PositionWrapper = styled.div`
  position: relative;
  top: -9px;
  left: -5px;
			pointer-events: none;
`;

const AnimationWrapper = styled.div`
  content: '';
  clear: both;
  height: 101px;
  width: 290px;
  position: absolute;
  display: block;
  background-image: url(assets/icon/pulse-animation-wide.gif);
  background-size: 100%;
  z-index: 9;
  background-repeat: no-repeat;
  pointer-events: none;
`;

const DragText = styled.div<SliderProps>`
	position: absolute;
	pointer-events: none;
	top: 0;
	left:  ${props => props.left || '0'};
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-family: "Open Sans";
  font-weight: 600;
  font-size: 1.625rem;
`;

const DragHandle = styled(animated.div)<SliderProps>`
  height:100%;
  width: 300px;
  min-width: 100%;
  background-color: transparent;
  text-align: ${(props) => props.flipped ? 'left' : 'right'};
  margin-left: ${(props) => props.flipped ? 'calc(275px - 60px)' : `calc(-300px + 60px)`};
  user-drag: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  position: relative;
  z-index: 5;

  img {
    height: 100%;
    width: auto;
    padding: 16px 0px;
    transform: ${({flipped}) => flipped ? 'scaleX(-1)' : 'scaleX(1)'};
    user-drag: none;
    touch-action: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -ms-user-drag: none;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    position: relative;
    z-index: 1;
  }
`;
interface SliderProps {
  position?: string;
  top?: string;
  marginTop?: string;
  margin?: string;
  width?: string;
  height?: string;
  left?: string;
  label?: string;
  flipped?: boolean;
  showAnimation?: boolean;
  onSlide?: () => void;
};

const Slider: React.FC<SliderProps> = ({ onSlide, flipped, label, margin, width, height, showAnimation, left }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const posRef = useRef<number>(0);
  const [isSlid, setIsSlid] = useState(false);

  const onRest = () => {
    if (flipped) {
      if (posRef.current < -202 ) {
        setIsSlid(true);
      } else {
        setIsSlid(false); 
      }
    } else {
      if (posRef.current > 200) {
        setIsSlid(true);
      } else {
        setIsSlid(false);
      }
    }
  };

  const [{ x }, api] = useSpring(() => ({ x: 0, onRest }));

  useEffect(() => {
    if (isSlid) {
      onSlide?.();
    }
    handleRef.current! && handleRef.current!.addEventListener( "touchstart", function(e: any){ onStart(e); }, false );
    function onStart ( touchEvent: any ) {
      if (touchEvent.cancelable) touchEvent.preventDefault();
    }
  }, [isSlid, onSlide]);

  const bind: any = useDrag(({ active, offset: [x], movement: [mx] }) => {

    let xPos = 0;
    const max = handleRef.current!.offsetWidth - imgRef.current!.offsetWidth;

    if (flipped) {
      if (active) {
        if (handleRef.current && imgRef.current) {
          handleRef.current.style.backgroundColor = 'white';
          imgRef.current.src = 'assets/icon/icon-unlock-black.png';
        }
        xPos = mx;
      } else {
        if (handleRef.current && imgRef.current) {
          handleRef.current.style.backgroundColor = 'black';
          imgRef.current.src = 'assets/icon/icon-unlock.gif';
        }
      }
    } else {
      if (max !== 0) {
        if (x > max) {
          xPos = max;
        } else if (active) {
          if (handleRef.current && imgRef.current) {
            handleRef.current.style.backgroundColor = 'white';
            imgRef.current.src = 'assets/icon/icon-unlock-black.png';
          }
          xPos = mx;
        } else  {
          if (handleRef.current && imgRef.current) {
            handleRef.current.style.backgroundColor = 'black';
            imgRef.current.src = 'assets/icon/icon-unlock.gif';
          }
        }
      }
    }

    posRef.current = xPos;
    api.start({ x: active ? mx : 0 , immediate: active }); 
  });

  return (
    <>
		{
			showAnimation &&
			<PositionWrapper>
				<AnimationWrapper>
				</AnimationWrapper>
			</PositionWrapper>
		}
    <SliderWrapper width={width} height={height} margin={margin}>
      <DragText left={left} >{label}</DragText>
      <DragHandle flipped={flipped} ref={handleRef} {...bind()} style={{ x }}>
        <img ref={imgRef} src='assets/icon/icon-unlock.gif' alt="drag-handle" />
      </DragHandle>
    </SliderWrapper>
    </>
  );
};

export default Slider;
