import React, { useState } from 'react';
import styled from "styled-components";
import { IonRow, IonCol } from "@ionic/react";
import { SimulatorPage } from "../pages/SimulatorEntry";
import Dropdown from '../components/Dropdown';
import Slider from '../components/Slider';

const StyledNavBar = styled(IonRow)`
	margin-top: 8px;
	padding: 3px 16px;
`;

const StyledImage = styled.img`
	opacity: 0;
	pointer-events: none;
`;

const StyledPText = styled.p<MealsProps>`
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
	margin: ${props => props.Margin};
`;

const DropDownWrapper = styled.div`
  .dropdown-list {
	max-height: 356px;
	overflow-y: scroll;
  }
`;

interface MealsProps {
	currentPage?: string;
	setCurrentPage?: (page: SimulatorPage) => void;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
	setIsMenuShown?: Function,
}

const Meals: React.FC<MealsProps> = ({ currentPage, setCurrentPage, setIsMenuShown }) => {

	const [mealTypeLabel, setMealTypeLabel] = useState('Meal Type');
	const [carbAmountLabel, setCarbAmountLabel] = useState('Usual For Me');
	const [unlocked, setUnlocked] = useState(false);

	return (


		<>
		{currentPage === "Meals" ? 

			<>

			<StyledNavBar class="ion-justify-content-start ion-align-items-center nav-bar">
				<img
					onClick={() => {
						setCurrentPage?.("entry");
					}
					}
					src={"assets/icon/back-arrow.png"}
					width="auto"
					height="34px"
					alt=""
				/>

				<StyledPText
					fontSize="22px"
					Margin="16px 0 16px 30px"
				>Announce Carbs
				</StyledPText>
				
			</StyledNavBar>
			<DropDownWrapper>
				<div className="dropdown-list">
					<Dropdown
						label={mealTypeLabel}
						onSelect={(item, setIsOpen) => {
							setMealTypeLabel(item.label);
							setIsOpen(false);
						}}
						items={[
							{
								id: 'Breakfast',
								label: "Breakfast",
							},
							{
								id: 'Lunch',
								label: "Lunch",
							},
							{
								id: 'Dinner',
								label: "Dinner",
							},
						]}
					/>
				{mealTypeLabel !== 'Meal Type' ?
					<Dropdown
						label={carbAmountLabel}
						onSelect={(item: any, setIsOpen) => {
							setCarbAmountLabel(item.label);
							setIsOpen(false);
						}}
						items={[
							{
								id: 'More',
								label: "More",
								icon: "assets/icon/icon-meal-more.png",
							},
							{
								id: 'Usual For Me',
								label: "Usual For Me",
								icon: "assets/icon/icon-meal-usual.png",
							},
							{
								id: 'Less',
								label: "Less",
								icon: "assets/icon/icon-meal-less.png",
							},
						]}
					/>
					: null
				}
				</div>
			</DropDownWrapper>
			{mealTypeLabel !== 'Meal Type' ? 
				<IonRow>
					
						<IonCol size="12" className="ion-text-center">
							<Slider margin="90px 0 0 0" label="Deliver" onSlide={() => { setUnlocked(true) }} />
						</IonCol>
						{unlocked ? 
						setCurrentPage?.("DeliverPrep")
						:
						null
						}
				</IonRow>
				: 
				null
			}

			</>
			:
			null
		}
		</>
	);
};

export default Meals;
