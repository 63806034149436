import React from 'react';
import styled from 'styled-components';

const SegmentedBar = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const StyledItem = styled.li<{ isActive: boolean}>`
  height: 2px;
  flex-grow: 1;
  background-color: ${props => props.isActive ? 'purple' : '#E6E7E8'};
  margin-right: 2px;
  &:last-of-type {
      margin-right: 0;
    }
`;

const FullBar = styled.hr`
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #8951FD 0%, #FEB4AF 100%);
`;

interface ContainerProps {
  num: number,
  currentProgress: number,
 }

const TutorialProgressBar: React.FC<ContainerProps> = ({ num, currentProgress }) => {
  const isActive = (i: number) => i < currentProgress;
  let markup;
  if (currentProgress < num) {
    markup = (
      <SegmentedBar>
        {[...Array(num)].map((e, i) =>
          <StyledItem key={i} isActive={isActive(i)} />
        )}
      </SegmentedBar>
    )
  } else {
    markup = <FullBar />
  }
  return markup
};

export default TutorialProgressBar;
