import React from 'react';
import {IonImg, IonItem, IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { SimulatorPage } from "../pages/SimulatorEntry";
import { iLetSetupPage } from "../pages/ILetSetup";

const StyledListItem = styled(IonItem) <ContainerProps>`
	margin: ${props => props.margin || '1rem auto'};
	padding: ${props => props.padding || '0 1rem'};
	border-top: ${props => props.borderTop};
	border-bottom: ${props => props.borderBottom};
	font-size: ${props => props.fontSize};
	--background: black;
	--background-activated-opacity:1;

	&:last-child {
		margin-top: ${props => props.marginTopLastChild}; //22px;
	}
`;

const StyledIonLabel = styled(IonLabel) <ContainerProps>`
	font-family: "Open Sans";
	font-weight: ${props => props.fontWeight || '400'};
	font-size: ${props => props.fontSize};
	margin: ${props => props.margin || '10px 0'};
	height: ${props => props.height};
	line-height: ${props => props.lineHeight};
	white-space: ${props => props.whitespace || 'nowrap'} !important;
`;

const StyledImg = styled(IonImg)<StyledImgProps>`
  width: ${props => props.width || '20px'};
  margin: ${props => props.margin || 'auto 6px 0 0'};
`;

const StyledImgChevron = styled(IonImg)<StyledImgChevronProps>`
  width: ${props => props.width || '10px'};
  margin-right: ${props => props.marginRight || '1rem'};
`;

const Wrapper = styled.div`
  display: block;
  margin: 16px 0;
`;

const WrapperFlex = styled.div`
  display: flex;
  align-items: top;
`;

const WrapperFlexCol = styled.div`
  display: flex;
  align-items: top;
  flex-direction: column;
`;

interface StyledImgProps {
	display?: string,
	width?: string,
	margin?: string,
}

interface StyledImgChevronProps {
	width?: string,
	marginRight?: string,
}

interface ContainerProps {
	item: { 
		label: string, 
		subLabel?: string, 
		href?: string, 
		popup?: any, 
		icon?: string, 
		iconTick?: boolean, 
		largeIcon?: string, 
		borderBottom?: boolean, 
		goToPage?: string,
		hideChevron?: boolean,
		hideIcon?: boolean,
	},
	i: number,
	currentProgress: number,
	setCurrentProgress: Function,
	padding?: string,
	margin?: string,
	width?: string,
	height?: string,
	borderTop?: string,
	borderBottom?: string,
	onClick?: any,
	fontWeight?: string,
	fontSize?: string,
	lineHeight?: string,
	whitespace?: string,
	setShowAlertPopup?: any,
	setShowAboutPopup?: any,
	setShowTherapyPopup?: any,
	setShowGeneralPopup?: any,
	setShowOtherPopup?: any,
	setShowAlertsPopup?: any,
	setShowMealsPopup?: any,
	setShowCartridgesPopup?: any,
	setShowInfusionPopup?: any,
	setShowAlgorithmPopup?: any,
	setShowSensorPopup?: any,
	setShowCGMAlertsPopup?: any,
	setShowCGMInfoPopup?: any,
	hideAlertItems?: any,
	setCurrentPage?: (page: SimulatorPage) => void,
	setCurrentIletPage?: (page: iLetSetupPage) => void,
	setHideMenu?: any,
	marginTopLastChild?: string,
}


const ListItem: React.FC<ContainerProps> = ({ 
	setCurrentPage, 
	setCurrentIletPage,
	setHideMenu,
	setShowAlertPopup, 
	setShowAboutPopup, 
	setShowTherapyPopup, 
	setShowGeneralPopup, 
	setShowOtherPopup, 
	setShowAlertsPopup, 
	setShowMealsPopup, 
	setShowCartridgesPopup, 
	setShowInfusionPopup, 
	setShowAlgorithmPopup, 
	setShowSensorPopup, 
	setShowCGMAlertsPopup, 
	setShowCGMInfoPopup, 
	hideAlertItems, 
	padding, 
	fontSize, 
	margin, 
	borderTop, 
	borderBottom,
	item, 
	i, 
	currentProgress, 
	setCurrentProgress,
	marginTopLastChild,
}, props) => {
	
	return (

		<StyledListItem {...props}
			
			detail={false}
			padding={padding}
			margin={margin}
			fontSize={fontSize}
			borderTop={borderTop}
			borderBottom={item.borderBottom && borderBottom}
			setCurrentPage={setCurrentPage}
			setCurrentIletPage={setCurrentIletPage}
			setHideMenu={setHideMenu}
			marginTopLastChild={marginTopLastChild}
			
			
			onClick={function() {
					
					// eslint-disable-next-line
					item.goToPage ? setCurrentPage?.(eval(`${'item.goToPage'}`)) : eval(`${item.popup}?.(true)`);
					// eslint-disable-next-line
					item.goToPage ? setCurrentIletPage?.(eval(`${'item.goToPage'}`)) : eval(`${item.popup}?.(true)`);
					if (item.goToPage === 'calculator') {
						setHideMenu(true)
					}
					
					
				}
			}
		>
			{ item.icon ? 
				
				<WrapperFlex>
					<StyledImg slot="start" width="32px" margin="auto 12px auto 0" src={item.icon} />
					{	
						!hideAlertItems &&
						<>
						
						<WrapperFlexCol>
							<StyledIonLabel {...props} margin="16px 0 0" fontSize="20px !important" fontWeight="600" lineHeight="24px" whitespace="normal" dangerouslySetInnerHTML={{__html: item.label}}></StyledIonLabel>
							<StyledIonLabel {...props} margin="4px 0 16px" fontSize="15px !important" dangerouslySetInnerHTML={{__html: item.subLabel}}></StyledIonLabel>
						</WrapperFlexCol>

						</>
					}
					
				</WrapperFlex>
				
				:
				
				<Wrapper>
					<WrapperFlex>
						{
							item.hideIcon ? 
							<StyledImg width="0" margin="0" slot="start" /> 
							: item.iconTick ? 
							<StyledImg width="27px" margin="0 20px 0 0" slot="start" src={"assets/icon/white-tick.png"} />
							:
							<StyledImg width="0" margin="auto 0 auto 0" slot="start" src={item.largeIcon} />
						}
						<WrapperFlexCol>
							<StyledIonLabel {...props} margin="0" fontSize="20px !important" lineHeight="22px" dangerouslySetInnerHTML={{__html: item.label}}></StyledIonLabel>
						{	
							!hideAlertItems &&
							
							<StyledIonLabel {...props} margin="4px 0 0" fontSize="15px !important" dangerouslySetInnerHTML={{__html: item.subLabel}}></StyledIonLabel>
							
						}
						</WrapperFlexCol>
					</WrapperFlex>
					
				</Wrapper>
			

			}
			{ !item.hideChevron && 
				<StyledImgChevron marginRight="0" width="22px" slot="end" src={'assets/icon/icon-chevron-white.png'} />
			}

		</StyledListItem>
	);
};

export default ListItem;
