import styled from "styled-components";
import { IonRow } from "@ionic/react";
import { SimulatorPage } from "../pages/SimulatorEntry";
import ContextList from '../components/ContextList'
import React, { useState } from 'react';
import Popup from '../components/Popup';



const StyledNavBar = styled(IonRow)`
	margin-top: 0;
	padding: 3px 16px;
`;

const StyledPText = styled.p<SettingsProps>`
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
	margin: ${props => props.Margin};
`;


interface SettingsProps {
	setCurrentPage?: (page: SimulatorPage) => void;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
	setIsMenuShown?: Function,
}

const Settings: React.FC<SettingsProps> = ({ setCurrentPage, setIsMenuShown }) => {
	const [showAboutPopup, setShowAboutPopup] = useState(false);
	const [showTherapyPopup, setShowTherapyPopup] = useState(false);
	const [showGeneralPopup, setShowGeneralPopup] = useState(false);
	const [showOtherPopup, setShowOtherPopup] = useState(false);


	const contentsData = [
		{
			label: "About iLet<sup>®</sup>",
			popup: 'setShowAboutPopup',
			hideIcon: true,
		},
		{
			label: "Therapy",
			popup: 'setShowTherapyPopup',
			hideIcon: true,
			
		},
		{
			label: "General",
			popup: 'setShowGeneralPopup',
			hideIcon: true,
		},
		{
			label: "Other",
			popup: 'setShowOtherPopup',
			borderBottom: true,
			hideIcon: true,
		}
	]

	return (

		
		<>

			<Popup
				show={showAboutPopup}
				setShow={setShowAboutPopup}
				center={true}
			>
				<p>This is where we keep your iLet<sup>®</sup>'s personal info...</p>
			</Popup>

			<Popup
				show={showTherapyPopup}
				setShow={setShowTherapyPopup}
				center={true}
			>
				<p>Set your CGM targets to usual, higher, or lower; toggle and schedule an automatic sleep CGM target; select CGM type being used; enter or change your body weight; and explore your advanced settings.</p>
			</Popup>

			<Popup
				show={showGeneralPopup}
				setShow={setShowGeneralPopup}
				center={true}
			>
				<p>With general settings, you can set the time and date, and give your iLet<sup>®</sup> a name.</p>
			</Popup>

			<Popup
				show={showOtherPopup}
				setShow={setShowOtherPopup}
				center={true}
			>
				<p>When you need to switch off, you can power down, reset, or factory reset your iLet<sup>®</sup>.</p>
			</Popup>

			<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
				<img
					onClick={() => {
						setCurrentPage?.("entry");
						setIsMenuShown?.(true);
					}
					}	
					src={"assets/icon/back-arrow.png"}
					width="auto"
					height="34px"
					alt=""
				/>

				<StyledPText
					fontSize="22px"
				>Settings
				</StyledPText>

				<img
					onClick={() => { setCurrentPage?.("entry") }}
					className="icon-home"
					src={"assets/icon/icon-home.png"}
					width="40px"
					height="auto"
					alt=""
				/>
			</StyledNavBar>

			<ContextList
				setShowAboutPopup={setShowAboutPopup}
				setShowTherapyPopup={setShowTherapyPopup}
				setShowGeneralPopup={setShowGeneralPopup}
				setShowOtherPopup={setShowOtherPopup}
				marginTop="0"
				margin="0"
				fontSize="24px"
				data={contentsData}
				padding="0"
				borderTop='2px solid white'
				borderBottom='2px solid white'
				setCurrentPage={setCurrentPage}
				marginTopLastChild="0px"
			/>



		</>
	);
};

export default Settings;
