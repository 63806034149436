import React from 'react';
import { IonList } from '@ionic/react';
import styled from 'styled-components';
import { SimulatorPage } from "../pages/SimulatorEntry";
import { iLetSetupPage } from "../pages/ILetSetup";

import ContextListItem from '../components/ContextListItem'

const StyledList = styled(IonList) <ContainerProps>`
	position: relative;
	z-index: 9;
	margin-top: ${props => props.marginTop || '2rem'};
	padding: ${props => props.padding || '0'};
	font-size: ${props => props.fontSize};
	width: ${props => props.width};
	left: ${props => props.left};
	background: black;
	--background: black;
`;

const PositionWrapper = styled.div<ContainerProps>`
	position: relative;
	top: ${props => props.positionTop || '-4px'};
	left: 0;
	pointer-events: none;
`;

const AnimationWrapper = styled.div`
	content: '';
	clear: both;
	height: 101px;
	width: 293px;
	position: absolute;
	display: block;
	background-image: url(assets/icon/pulse-animation-wide.gif);
	background-size: 100%;
	z-index: 10;
	background-repeat: no-repeat;
	pointer-events: none;
`;

interface ContainerProps {
	data: Array<{ 
		label: string, 
		subLabel?: string, 
		href?: string, 
		popup?: any,
		iconTick?: boolean,
		icon?: string, 
		largeIcon?: string, 
		borderBottom?: boolean,
		hideChevron?: boolean,
		hideIcon?: boolean
		
	}>,
	lines?: 'full' | 'inset' | 'none',
	currentProgress?: any,
	setCurrentProgress?: any,
	padding?: string,
	margin?: string,
	borderTop?: string,
	borderBottom?: string,
	marginTop?: string,
	fontSize?: string,
	onClick?: any,
	setShowAlertPopup?: any,
	setShowAboutPopup?: any,
	setShowTherapyPopup?: any,
	setShowGeneralPopup?: any,
	setShowOtherPopup?: any,
	setShowAlertsPopup?: any,
	setShowMealsPopup?: any,
	setShowCartridgesPopup?: any,
	setShowInfusionPopup?: any,
	setShowAlgorithmPopup?: any,
	setShowSensorPopup?: any,
	setShowCGMAlertsPopup?: any,
	setShowCGMInfoPopup?: any,
	hideAlertItems?: any,
	setCurrentPage?: (page: SimulatorPage) => void,
	setCurrentIletPage?: (page: iLetSetupPage) => void,
	setHideMenu?: any,
	width?: string,
	left?: string,
	positionTop?: string,
	showAnimation?: boolean,
	marginTopLastChild?: string,
}

const List: React.FC<ContainerProps> = ({ 
	setCurrentPage, 
	setCurrentIletPage, 
	setHideMenu,
	setShowAlertPopup, 
	setShowAboutPopup, 
	setShowTherapyPopup, 
	setShowGeneralPopup, 
	setShowOtherPopup, 
	setShowAlertsPopup, 
	setShowMealsPopup, 
	setShowCartridgesPopup, 
	setShowInfusionPopup, 
	setShowAlgorithmPopup, 
	setShowSensorPopup, 
	setShowCGMAlertsPopup, 
	setShowCGMInfoPopup, 
	hideAlertItems, 
	fontSize, 
	marginTop, 
	data, 
	currentProgress, 
	setCurrentProgress, 
	padding, 
	margin, 
	borderTop, 
	borderBottom,
	lines,
	showAnimation,
	positionTop,
	width,
	left,
	marginTopLastChild,
}, props) => {
	return (
		<>

			{
			showAnimation &&
				<PositionWrapper {...props} positionTop={positionTop}>
					<AnimationWrapper>
					</AnimationWrapper>
				</PositionWrapper>
			}

			<StyledList {...props}
				marginTop={marginTop}
				lines={lines}
				width={width}
				left={left}
				>
				
				{data.map((item, i) =>
					<ContextListItem
						hideAlertItems={!hideAlertItems}
						padding={padding}
						margin={margin}
						borderTop={borderTop}
						borderBottom={borderBottom}
						fontSize={fontSize}
						key={i}
						i={i}
						currentProgress={currentProgress}
						setCurrentProgress={setCurrentProgress}
						setCurrentIletPage={setCurrentIletPage}
						setHideMenu={setHideMenu}
						item={item}
						setShowAlertPopup={setShowAlertPopup}
						setShowAboutPopup={setShowAboutPopup}
						setShowTherapyPopup={setShowTherapyPopup}
						setShowGeneralPopup={setShowGeneralPopup}
						setShowOtherPopup={setShowOtherPopup}
						setCurrentPage={setCurrentPage}
						setShowAlertsPopup={setShowAlertsPopup}
						setShowMealsPopup={setShowMealsPopup}
						setShowCartridgesPopup={setShowCartridgesPopup}
						setShowInfusionPopup={setShowInfusionPopup}
						setShowAlgorithmPopup={setShowAlgorithmPopup}
						setShowSensorPopup={setShowSensorPopup}
						setShowCGMAlertsPopup={setShowCGMAlertsPopup}
						setShowCGMInfoPopup={setShowCGMInfoPopup}
						marginTopLastChild={marginTopLastChild}
					/>
				)}
			</StyledList>
		</>
	);
};

export default List;
