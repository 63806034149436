import { IonGrid, IonRow, IonCol, IonText, IonInput } from "@ionic/react";
import StatusBar from "../components/StatusBar";
import Menu from "../components/Menu";
import ILetFrame from "../components/ILetFrame";
import { Device } from '@capacitor/device';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ContextList from '../components/ContextList'
import Slider from '../components/Slider';
import PopupBottom from '../components/PopupBottom';
import Button from '../components/Button';

const StyledNavBar = styled(IonRow)`
	margin-top: 8px;
	padding: 3px 20px;
`;

const StyledInput = styled(IonInput)`
	position: relative;
	z-index: 9;
	max-width: 116px;
    height: 45px;
	margin-right: 8px;
    background: white;
	border-radius: 50px;
	color: black;
	text-align: center;
	--padding-end: 0;
	pointer-events: none;
`;

const StyledPText = styled.p<ContainerProps>`
	font-family: "Open Sans";
	font-weight: ${(props) => props.fontWeight};
	font-size: ${(props) => props.fontSize};
	margin: ${(props) => props.Margin};
	text-align: center;
	z-index:10;
	position:relative;
`;

const StyledCol = styled(IonCol)<ContainerProps>`
	position: ${props => props.position};
	text-align: ${props => props.textAlign};
	background: ${props => props.bg};
	margin: ${props => props.Margin};
	border-bottom: ${props => props.borderBottom};
	border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
	border-bottom-right-radius: ${props => props.borderBottomRightRadius};
	z-index: ${props => props.zIndex};
	padding: ${props => props.padding};
	font-size: ${props => props.fontSize};
	height:  ${props => props.height};
`;

const StyledButton = styled(IonCol)<ContainerProps>`
	position: relative;
	display: flex;
	justify-content: center;
    align-items: center;
	border: ${props => props.border || '2px solid white'};
	border-radius: 40px;
	height: 44px;
	margin: 3px 5px;
	font-weight: bold;
	font-size: 21px;
	z-index: 9;
	
	&:active {
		border: 2px solid white;
		background: white;
		color: black;
	}
`;

const StyledGoBionic = styled(IonRow)<ContainerProps>`
	position: relative;
	z-index: 1;
	height: 230px;
	margin: 30px auto 0 auto;
	max-width: 230px;
	background-image: url(../assets/global/go-bionic-circle.png);
	background-size: 100%;
	background-repeat: no-repeat;
	overflow: hidden;
	transform: rotate(${(props) => props.deg ? props.deg * 10 : null }deg);
    transition: transform 1s linear;
`;

const StyledImg = styled.img<ContainerProps>`
	position: relative;
	margin: ${props => props.Margin};
	z-index: 12;
`;

const StyledRow = styled(IonRow)<ContainerProps>`
	padding: ${props => props.padding};
`;

const PositionElement = styled.div`
	position: relative;
	top: -89px;
    left: -35px;
	pointer-events: none;
`;

const PositionWrapper = styled.div`
	position: relative;
	top: 0px;
	left: -1px;
	pointer-events: none;
`;

const AnimationWrapper = styled.div`
	content: '';
	clear: both;
	height: 290px;
	width: 290px;
	position: absolute;
	left: 1px;
	display: block;
	background-image: url(assets/icon/circle-pulse-animation-large.gif);
	background-size: 100%;
	z-index: 9;
	background-repeat: no-repeat;
	pointer-events: none;
`;

const StyledBellIcon = styled.div`
	height: 96px;
	width: 86px;
	position: absolute;
	top: 87px;
	background-repeat: no-repeat;
	left: 240px;
	padding: 11px;
	display: block;
	background-image: url(assets/icon/circle-pulse-animation.gif);
	background-size: 100%;
`;

const HoldingStyle = styled.div`
    position: relative;
	z-index:10;
    .ion-text-center {
        position: absolute;
		top:60px;
		z-index:12;
    }
`;

interface ContainerProps {
	position?: string;
	fontWeight?: string;
	fontSize?: string;
	Margin?: string;
	padding?: string;
	height?: string;
	zIndex?: string;
	textAlign?: string;
	bg?: string;
	border?: string;
	borderBottom?: string;
	borderBottomLeftRadius?: string;
	borderBottomRightRadius?: string;
	hideMenuItems?: Boolean;
	hideAlertItems?: Boolean;
	value?: number;
	deg?: number;
};


export type iLetSetupPage = "goBionic" | "notifications" | "iLetList" | "calculator" | "confirmWeight" | "goBionic2" | "homeScreen" | "animationScreen";

const IletSetup: React.FC<ContainerProps> = ({padding, height, hideAlertItems}, props) => {
	const [currentIletPage, setCurrentIletPage] = useState<iLetSetupPage>("goBionic");
	const [hideCloseMenuButton, setHideCloseMenuButton] = useState(true);
	const [hideStatusBarBellIcon, setHideStatusBarBellIcon] = useState(false);
	const [hideStatusBarStatusIcon, setHideStatusBarStatusIcon] = useState(true);
	const [hideBorderBottom, setHideBorderBottom] = useState(true);
	const [bellWithNotification, setBellWithNotification] = useState(true);
	const [showPopup1, setShowPopup1] = useState(true);
	const [showPopup2, setShowPopup2] = useState(true);
	const [showPopup3, setShowPopup3] = useState(false);
	const [showPopup4, setShowPopup4] = useState(false);
	const [hideMenu, setHideMenu] = useState(false);
	const [hideStatusBar, setHideStatusBar] = useState(false);
	const [convertedToKg, setConvertedToKg] = useState('Min 2, max 500 pounds');
	const [isInputEmpty, setIsInputEmpty] = useState(true);
	const [unlocked, setUnlocked] = useState(false);
	const [pounds, setPounds] = useState('');
	const [kilograms, setKilograms] = useState('');
	const [isPulseAnimationVisible, setIsPulseAnimationVisible] = useState(false);
	const [device, setDevice] = useState<string>();
	const [deg, setDeg] = useState(0);
	const [setUpDataStore, setSetUpDataStore] = useState([
		{
			label: "CGM Sensor",
			iconTick: false,
			hideChevron: false,
		},
		{
			label: "Insulin Cartridge",
			iconTick: false,
			hideChevron: true,
		},
		{
			label: "Insulin Set",
			iconTick: false,
			hideChevron: true,
		},
		{
			label: "Enter Weight",
			iconTick: false,
			goToPage: 'calculator',
			hideChevron: true,
		}
	])

	const contentsData = [
		{
			label: "iLet<sup>&reg;</sup> Setup",
			subLabel: "2022-Jan-01, 12:00 pm",
			largeIcon: "assets/icon/icon-warning-square.png",
			borderBottom: true,
			goToPage: 'iLetList',
		},
	]

	useEffect(()=> {
		setTimeout(() => {
			setDeg(deg + 5);
		}, 1000);
		return () => clearTimeout();
	},[deg])

	const platform = async () => {
		const info = await Device.getInfo();
		return info.platform;
	};
	
	useEffect(() => {
		platform()
		.then((pl) => { setDevice(pl) })
		.catch((err) => { return err });
	})

	let colorSet: string = "";
	switch(device){
		case "web":
			colorSet = "light";
			break;
		case "ios":
			colorSet = "light";
			break;
		case "android":
			colorSet = "dark";
			break;
	};
	
	function convertPoundsToKg(value: any) {
		const Kilograms = value / 2.205;
		return Kilograms;
	} 

	function handleChange(ev: any) {
		const inputField = document.querySelector('.native-input') as HTMLInputElement;
		console.log(ev.target.value);
		inputField.value += `${ev.target.value}`;

		setConvertedToKg(`${(convertPoundsToKg(inputField.value)).toFixed(1)} kilograms`);

		setPounds(`${inputField.value}`)
		setKilograms(`${(convertPoundsToKg(inputField.value)).toFixed(1)} kilograms`);
		
		if (parseInt(inputField.value) >= 500) {
			inputField.value = '500';
			setConvertedToKg(`${(convertPoundsToKg(inputField.value)).toFixed(1)} kilograms`);
		}

		if (inputField.value === '') {
			setIsInputEmpty(true)
			setConvertedToKg('Min 2, max 500 pounds');
		} else {
			setIsInputEmpty(false)
		}
	}

	function deleteValue(ev: any) {
		const inputField = document.querySelector('.native-input') as HTMLInputElement;
		const newValue = `${inputField.value}`.slice(0, -1);
		inputField.value = '';
		inputField.value += newValue;

		setConvertedToKg(`${(convertPoundsToKg(inputField.value)).toFixed(1)} kilograms`);

		if (inputField.value === '') {
			setIsInputEmpty(true)
			setConvertedToKg('Min 2, max 500 pounds');
		} else {
			setIsInputEmpty(false)
		}
		
	}

	const showPopUpVideoEnd = () => (setShowPopup4(true));
								

	return (

		<>
			<ILetFrame>
				
				
				{ !hideMenu &&
					!hideCloseMenuButton &&
						<Menu hideMenuItems={true} hideAlertItems={true}  hideBorderBottom={hideBorderBottom}>
								<img
									src="../assets/icon/icon-close-menu.png"
									width="82px"
									height="auto"
									alt=""
								/>
						</Menu>
					
				}

				{
					!hideStatusBar &&
					<StatusBar showBellIcon={hideStatusBarBellIcon} showStatusIcon={hideStatusBarStatusIcon}></StatusBar>
				}

				{currentIletPage === 'goBionic' &&
					<PositionElement>
						<StyledBellIcon></StyledBellIcon>
					</PositionElement>
				}

				
				
				{ !hideMenu &&
					<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
						
						
						<img
							className="icon-menu"
							src="../assets/icon/icon-menu.png"
							width="44px"
							height="auto"
							alt=""
						/>
							
						<img
							className="icon-cartridge"
							src={`../assets/icon/icon-cartridge${setUpDataStore[2].iconTick ? "" : `-empty`}.png`}
							width={`${setUpDataStore[2].iconTick ? "25px" : "44px"}`}
							height="auto"
							alt=""
						/>

						
						<img
							onClick={() => {
								setHideStatusBarBellIcon(true);
								setHideStatusBarStatusIcon(false);
								setHideBorderBottom(false);
								setHideCloseMenuButton(false);
								setCurrentIletPage('notifications');
								setBellWithNotification(false);
								setShowPopup1(true);
							}}
							className="icon-bell"
							src={bellWithNotification ? "../assets/icon/icon-bell-notification.png" : "../assets/icon/icon-bell.png"}
							width="42px"
							height="auto"
							alt=""
						/>
					</StyledNavBar>

				}

				

				{currentIletPage === "goBionic" ? (
					<>
						<StyledGoBionic
							class="text-center ion-align-items-center go-bionic"
						>
							<IonCol size="12" className="ion-text-center">
								{/* <img
									className="icon-arrow-right-down"
									src="../assets/icon/icon-arrow-right.png"
									width="26px"
									height="auto"
									alt=""
								/>
								<IonText>
									<StyledPText
										fontWeight="bold"
										fontSize="32px"
										Margin="0 0 7px 0"
										className="mg-value"
									>
										115
									</StyledPText>
								</IonText>
								<IonText>
									<StyledPText Margin="0">mg/dL</StyledPText>
								</IonText> */}
							</IonCol>
						</StyledGoBionic>

						<IonRow>
						
							<IonCol size="12" className="ion-text-center">
								<StyledImg
									Margin="16px 0 0 0"
									className="icon-meal"
									src="../assets/icon/icon-meal.png"
									width="60px"
									height="auto"
									alt=""
								/>
							</IonCol>
							
							
						</IonRow>

						
					</>
				) 

				: currentIletPage === "notifications" ? (
					
					<>
						{!hideAlertItems &&
							<>
								<StyledCol position="relative" zIndex="9" padding="0" bg="black" size="12">
									<StyledPText fontSize="24px" fontWeight="400" Margin="18px 0 24px">Notifications</StyledPText>
									<ContextList
										hideAlertItems={!hideAlertItems}
										marginTop="0"
										margin="0"
										data={contentsData}
										padding="0"
										borderTop='2px solid white'
										borderBottom='2px solid white'
										setCurrentIletPage={setCurrentIletPage}
										showAnimation={true}
										positionTop={device !== 'ios' ? "22px" : "4px"}
										width="264px"
										left="15px"
										marginTopLastChild="30px"
									/>
								</StyledCol>
								
								<StyledCol
									borderBottomLeftRadius="4px"
									borderBottomRightRadius="4px"
									height="152px" bg="black" size="12">
								</StyledCol>
								
							</>
						}
						<PopupBottom
							show={showPopup1}
							setShow={setShowPopup1}
							center={true}
							button={false}
							backgroundOverlay={false}
						>
							<p>Click 'iLet<sup>&reg;</sup> Setup' to get started.</p>
						</PopupBottom>
					</>
				) 

				: currentIletPage === "iLetList" ? (
					<>
						<StyledRow padding="12px 0 0 16px" className="ion-justify-content-start ion-align-items-center">
							<img
								src="../assets/icon/icon-arrow-back-small.png"
								width="16px"
								height="26px"
								alt=""
							/>
							<StyledCol>
								<StyledPText 
									fontSize="24px" 
									fontWeight="400"
									Margin="0 0 0 -32px">
									iLet<sup>&reg;</sup> Setup
								</StyledPText>
							</StyledCol>
						</StyledRow>
						<StyledCol position="relative" zIndex="9" padding="0" bg="black" size="12">
							<ContextList
								lines="none"
								marginTop="0"
								margin="0"
								fontSize="18px"
								data={setUpDataStore}
								padding="0"
								setCurrentIletPage={setCurrentIletPage}
								setHideMenu={setHideMenu}
								showAnimation={isPulseAnimationVisible}
								positionTop="162px" //141
								width="264px"
								left="15px"
								marginTopLastChild={`${setUpDataStore[2].iconTick ? "22px" : "0"}`}
							/>
						</StyledCol>

						<PopupBottom
							show={showPopup2}
							setShow={setShowPopup2}
							label="Next page"
							center={false}
							button={true}
							disableBackground={true}
							backgroundOverlay={false}
							onClick={() => {
								setHideStatusBarStatusIcon(true);
								setShowPopup2(false)
								setShowPopup3(true)

								setSetUpDataStore(
									[
										{
											label: "CGM Sensor",
											iconTick: true,
											hideChevron: true,
										},
										{
											label: "Insulin Cartridge",
											iconTick: true,
											hideChevron: true,
										},
										{
											label: "Insulin Set",
											iconTick: true,
											hideChevron: true,
										},
										{
											label: "Enter Weight",
											iconTick: false,
											goToPage: 'calculator',
											hideChevron: false,
										}
									]
								)
								
									
								
								}
							}
							>
						</PopupBottom>

						<PopupBottom
							show={showPopup3}
							setShow={setShowPopup3}
							label="Lets go!"
							center={true}
							button={true}
							disableBackground={true}
							backgroundOverlay={true}
							onClick={() => {
									setShowPopup3(false)
									setIsPulseAnimationVisible(true)
								}
							}

							
						>
							<p>In just a few quick steps, we'll help<br/>you get to know your iLet<sup>&reg;</sup>.
							<br/>
							<br/>
							For now, let's skip a few steps (pairing<br/>a CGM sensor, filling an insulin<br/>cartridge and applying an infusion<br/>set), but in the real world, you'll do<br/>these when you're setting up.
							
							</p>
						</PopupBottom>
					</>
				)

				: currentIletPage === "calculator" ? (
					<>
						<IonText>
							<StyledPText
								fontSize="19px"
								Margin="7px 0 8px 0"
							>
								Body Weight
							</StyledPText>
						</IonText>

					
						<StyledRow class="ion-justify-content-end ion-align-items-center">
							<StyledInput></StyledInput>
							<StyledPText
								fontSize="20px"
								Margin="0 15px 0 0"
							>
								pounds
							</StyledPText>
						</StyledRow>

						<IonText>
							<StyledPText
								fontSize="20px"
								Margin="7px 0 5px 0"
							>
								{convertedToKg}
							</StyledPText>
						</IonText>

						<IonGrid>
							<IonRow className="ion-align-items-center">
								<StyledButton onClick={handleChange} value={1} className="ion-text-center">1</StyledButton>
								<StyledButton onClick={handleChange} value={2} className="ion-text-center">2</StyledButton>
								<StyledButton onClick={handleChange} value={3} className="ion-text-center">3</StyledButton>
							</IonRow>
							<IonRow>
								<StyledButton onClick={handleChange} value={4} className="ion-text-center">4</StyledButton>
								<StyledButton onClick={handleChange} value={5} className="ion-text-center">5</StyledButton>
								<StyledButton onClick={handleChange} value={6} className="ion-text-center">6</StyledButton>
							</IonRow>
							<IonRow>
								<StyledButton onClick={handleChange} value={7} className="ion-text-center">7</StyledButton>
								<StyledButton onClick={handleChange} value={8} className="ion-text-center">8</StyledButton>
								<StyledButton onClick={handleChange} value={9} className="ion-text-center">9</StyledButton>
							</IonRow>
							<IonRow className="ion-justify-content-end">
								<StyledButton border="2px solid black" className="ion-text-center"></StyledButton>
								<StyledButton onClick={handleChange} value={0} className="ion-text-center">0</StyledButton>
								<StyledButton onClick={deleteValue} border="2px solid black" className="ion-text-center">
									<img
										
										src={"assets/icon/delete-button.png"}
										width="auto"
										height="28px"
										alt=""
									/>
								</StyledButton>
							</IonRow>
						</IonGrid>
						
						{
							!isInputEmpty &&
						
							<Button
								label="Next"
								color="white"
								fill="outline"
								fontSize="20px"
								width="276px"
								margin="20px 0 0 8px"
								padding="0 16px"
								className="addAnimation"
								onClick={() => {
									setCurrentIletPage('confirmWeight');
								}}
							/>

						}
					</>
				) 

				: currentIletPage === "confirmWeight" ? (
					<>
						<StyledRow padding="12px 0 0 16px" className="ion-justify-content-start ion-align-items-center">
							<img
								src="../assets/icon/back-arrow.png"
								width="auto"
								height="34px"
								alt=""
							/>
							<StyledCol>
								<StyledPText 
									fontSize="22px" 
									fontWeight="400"
									Margin="0 0 0 -24px">
									Confirm Weight
								</StyledPText>
							</StyledCol>		
						</StyledRow>

						<StyledCol>
							<StyledPText
								fontWeight="700"
								fontSize="20px"
								Margin="90px 0 5px">
								{pounds} pounds
							</StyledPText>
							<StyledPText
								fontSize="18px"
								Margin="0 0 90px">
								{kilograms}
							</StyledPText>
						</StyledCol>

						<IonRow>
							<IonCol size="12" className="ion-text-center">
								<Slider left="10px" 
								showAnimation={true} 
								width="245px"
								height="54px"
								margin="13px 16px 0px 18px" 
								label="Go Bionic!" 
								onSlide={() => { 
										setUnlocked(true)
										setCurrentIletPage('goBionic2');
										setTimeout(() => {
											setCurrentIletPage('homeScreen')
											setHideStatusBarBellIcon(false);
										}, 2000);
									}} />
							</IonCol>
						</IonRow>


					</>
				)

				: currentIletPage === "goBionic2" ? (
					<>
						<StyledImg
							Margin="90px 0 0 0"
							src="../assets/iletsetup/gobionic.jpg"
							width="512px"
							height="auto"
							alt=""
							
						/>

						<PopupBottom
							show={true}
							setShow={setShowPopup1}
							center={true}
							button={false}
							backgroundOverlay={false}
						>
							<p>It's as simple as that!<br/>Now let's see the algorithm at work.</p>
						</PopupBottom>


					</>
				)

				: currentIletPage === "homeScreen" ? (
					<>	
						<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
							
							{
								unlocked ? 
								<img
									className="icon-menu"
									src="../assets/icon/icon-menu.png"
									width="44px"
									height="auto"
									alt=""
								/>
								:
								<img
									className="icon-menu"
									src="../assets/icon/icon-lock.png"
									width="44px"
									height="auto"
									alt=""
								/>
							}
							<img
								className="icon-cartridge"
								src="../assets/icon/icon-cartridge.png"
								width="25px"
								height="auto"
								alt=""
							/>
							<img
								className="icon-bell"
								src="../assets/icon/icon-bell.png"
								width="48px"
								height="auto"
								alt=""
							/>
						</StyledNavBar>
						
						{
							unlocked &&
							<PositionWrapper>
								<AnimationWrapper>
								</AnimationWrapper>
							</PositionWrapper>
						}
						
						<HoldingStyle>
                            <StyledGoBionic deg={deg} className="text-center ion-align-items-center go-bionic"
							onClick={() => {
								unlocked &&
								setCurrentIletPage("animationScreen");
								unlocked &&
								setHideStatusBar(true);
							}}
						/>
							<IonCol size="12" className="ion-text-center">
								<img
									className="icon-arrow-right"
									src="../assets/icon/icon-arrow-right.png"
									width="36px"
									height="auto"
									alt=""
								/>
								<IonText>
									<StyledPText
										fontWeight="600"
										fontSize="36px"
										Margin="4px 0"
										className="mg-value"
									>
										115
									</StyledPText>
								</IonText>
								<IonText>
									<StyledPText fontSize="20px" Margin="0">mg/dL</StyledPText>
								</IonText>
							</IonCol>
                        </HoldingStyle>

						<IonRow>
							{
							!unlocked ?
							<IonCol size="12" className="ion-text-center">
								<Slider left="10px" showAnimation={true} width="auto" margin="14px 16px 0px 18px" label="Unlock" onSlide={() => { setUnlocked(true) }} />
							</IonCol>
							:
							<IonCol size="12" className="ion-text-center">
								<StyledImg
								onClick={
									() => { }
								}
								className="icon-meal"
								Margin="16px 0 0 0"
								src="../assets/icon/icon-meal.png"
								width="60px"
								height="auto"
								alt=""
							/>
							</IonCol>
							}
							
						</IonRow>
						


					</>
				)

				: currentIletPage === "animationScreen" ? (
					<>
						<video className="graph-video" width="291px" height="auto" autoPlay playsInline onEnded={() => showPopUpVideoEnd()}>
							<source src="../assets/video/graph-animation-video.mp4" type="video/mp4"></source>
						</video>

						<PopupBottom
							show={showPopup4}
							setShow={setShowPopup4}
							label="Back to Scenarios"
							center={true}
							button={true}
							backgroundOverlay={true}
							href="Scenarios"
						>
							<p>Head back to explore<br/>some more scenarios.</p>
						</PopupBottom>


					</>
				)

				: null}
			</ILetFrame>

			
		</>
	);
};

export default IletSetup;
