import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { IonRow, IonCol } from "@ionic/react";
import Slider from '../components/Slider';
import { SimulatorPage } from "../pages/SimulatorEntry";

const StyledNavBar = styled(IonRow)`
  margin-top: 8px;
  padding: 3px 16px;
`;

const StyledImage = styled.img`
	opacity: 0;
	pointer-events: none;
`;

const StyledPText = styled.p<DeliverProps>`
    font-family: "Open Sans";
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
    margin: ${props => props.Margin};
    width: 100%;
    text-align: center;
`;


interface DeliverProps {
    currentPage?: string;
    setCurrentPage?: (page: SimulatorPage) => void;
    marginTop?: string;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
	setIsMenuShown?: Function,
}

const DeliverPrep: React.FC<DeliverProps> = ({ currentPage, setCurrentPage, setIsMenuShown }) => {

    const [unlocked, setUnlocked] = useState(false);
    
    useEffect(() => {
        setTimeout(() => {
            setCurrentPage?.("Starting");
        }, 5000);
        return () => clearTimeout();
    }, [setCurrentPage]);

	return (

        <>
            {currentPage === "DeliverPrep" ? 

            <>
            
                <StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">

                    <StyledPText
                        fontSize="22px"
                    >Announce Carbs
                    </StyledPText>

                    <StyledImage
                        onClick={() => { setCurrentPage?.("entry") }}
                        className="icon-home"
                        src={"assets/icon/icon-home.png"}
                        width="40px"
                        height="auto"
                        alt=""
                    />
                </StyledNavBar>

                <IonRow>
                    <StyledPText
                        Margin="0 0 0 0"
                        fontSize="22px"
                    >Preparing to deliver
                    </StyledPText>
                </IonRow>
                    {
                    !unlocked ?
                    <IonRow>
                        <IonCol size="12" className="ion-text-center">
                            <Slider margin="192px 0 0 0" flipped={true} label="Cancel" onSlide={() => { setUnlocked(true) }} />
                        </IonCol>
                    </IonRow>
                        :
                        setCurrentPage?.("entry")
                    }
            </>
            :null
        }

        </>
	);
};

export default DeliverPrep;