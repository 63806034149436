import React from 'react';
import { IonCard } from '@ionic/react';
import styled from 'styled-components';

const StyledCard = styled(IonCard)<ContainerProps>`
  background: ${props => props.bg || '#ffffff'};
  color: ${props => props.color || '#000000'};
  border: ${props => props.border || '0'};
  border-radius: 48px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'space-between'};
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: ${props => props.marginBottom || '0'};
  height: ${props => props.height || '0'};
  font-size: 30px;
`;

const Row = styled.div`
  
`;

const RowTop = styled(Row)`
  
`;

const RowCenter = styled(Row)`
`;

const RowBottom = styled(Row)`
`;

interface ContainerProps {
  bg?: string,
  color?: string,
  height?: string,
  border?: string,
  marginBottom?: string,
  top?: React.ReactNode,
  center?: React.ReactNode,
  bottom?: React.ReactNode,
  justifyContent?: string,
 };

const Card: React.FC<ContainerProps> = (props) => {
  return (
    <StyledCard {...props}>
      <RowTop>
        {props.top}
      </RowTop>
      <RowCenter>
        {props.center}
      </RowCenter>
      <RowBottom>
        {props.bottom}
      </RowBottom>
    </StyledCard>
  );
};

export default Card;
