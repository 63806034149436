import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { IonRow, IonCol } from "@ionic/react";
import { SimulatorPage } from "../pages/SimulatorEntry";
import Slider from '../components/Slider';
import ProgressBar from '../components/ProgressBar';

const StyledNavBar = styled(IonRow)`
    margin-top: 8px;
    padding: 3px 16px;
`;

const StyledImage = styled.img`
	opacity: 0;
	pointer-events: none;
`;

const StyledPText = styled.p<StartingProps>`
    font-family: "Open Sans";
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
    margin: ${props => props.Margin};
    width: 100%;
    text-align: center;
`;


interface StartingProps {
    currentPage?: string; 
    setCurrentPage?: (page: SimulatorPage) => void;
    counterHandler?: Function;
    counter?: number;
    marginTop?: string;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
	setIsMenuShown?: Function,
}

const Starting: React.FC<StartingProps> = ({ currentPage, setCurrentPage, setIsMenuShown }) => {

    const [unlocked, setUnlocked] = useState(false);
    const [counter, setCounter] = useState(20);
    const [textChange, setTextChange] = useState("Starting...");
    
    useEffect(()=> {
        counter < 100 && setTimeout(() => {
            setCounter(counter + 20);
            if (counter === 40){
                setTextChange("Delivering...");
            }
            if (counter === 80){
                setTimeout(() => {
                    setCurrentPage?.("entry");
                }, 1000)
            }
        }, 500);
        return () => clearTimeout();
	},[counter, setCurrentPage])

	return (
        <>

        {currentPage === "Starting" ? 

        <>
		
			<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">

				<StyledPText
					fontSize="22px"
				>Announce Carbs
				</StyledPText>

				<StyledImage
					onClick={() => { setCurrentPage?.("entry") }}
					className="icon-home"
					src={"assets/icon/icon-home.png"}
					width="40px"
					height="auto"
					alt=""
				/>
			</StyledNavBar>

			<IonRow>
                <StyledPText
                    Margin="0 0 0 0"
					fontSize="22px">
                    {textChange}
                </StyledPText>
            </IonRow>

            <IonRow>
                <ProgressBar bgcolor="white" completed={`${counter}`}/>
            </IonRow>
            {
                !unlocked ?
                <IonRow>
                    <IonCol size="12" className="ion-text-center">
                        <Slider margin="62px 0 0 0" flipped={true} label="Cancel" onSlide={() => { setUnlocked(true) }} />
                    </IonCol>
                </IonRow>
                :
                setCurrentPage?.("entry")
                    
            }
            
        </>
        : null
        }

        </>
	);
};

export default Starting;