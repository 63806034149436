import React, { useState, useEffect } from 'react';
import { Device } from '@capacitor/device';
import styled from "styled-components";
import Button from './Button';
import { arrowForward } from 'ionicons/icons';

interface PopupProps {
	show?: boolean;
	setShow?: (show: boolean) => void;
	children?: React.ReactNode;
	center?: boolean;
	height?: string;
	button?: boolean;
	label?: string;
	disableBackground?: boolean;
	backgroundOverlay?: boolean;
	href?: string;
	zIndex?: string;
	backgroundColor?: string;
	onClick?: Function;
}

const PopupWrapper = styled.div<PopupProps>`
	position: absolute;
	bottom: 0;
	left:0;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	z-index: ${props => props.zIndex};
	background-color: ${props => props.backgroundColor};
`;
const PopupInner = styled.div<PopupProps>`
	position: absolute;
	bottom: 0;
	background-color: white;
	height: ${props => props.height};
	width: 100%;
	border-top-left-radius: 3rem;
	border-top-right-radius: 3rem;

`;

const PopupContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: space-between;
`;

const PopupCenter = styled.div`
	width: 100%;
	height: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	text-align: center;
	color: #000000;
`;




const PopupBottom: React.FC<PopupProps> = ({ children, show, setShow, label, center, button, disableBackground, backgroundOverlay, href, onClick }, props) => {

	const [device, setDevice] = useState<string>();

	const platform = async () => {
		const info = await Device.getInfo();
		return info.platform;
	};
	
	useEffect(() => {
		platform()
		.then((pl) => { setDevice(pl) })
		.catch((err) => { return err });
	})

	let colorSet: string = "";
	switch(device){
		case "web":
			colorSet = "dark";
			break;
		case "ios":
			colorSet = "dark";
			break;
		case "android":
			colorSet = "light";
			break;
	};

	return (
		<>
			{show ?
				(<PopupWrapper zIndex={disableBackground ? '99999' : '0'}  backgroundColor={backgroundOverlay ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)'}>
					<PopupInner height="auto">
						<PopupContent>
							{center ? <PopupCenter>{children}</PopupCenter> : children}

							{
								button &&
								<Button
									label={label}
									color={colorSet}
									height="52px"
									maxWidth="400px"
									width="290px"
									margin="0 auto"
									href={href}
									onClick={onClick}
									direction="back"
									icon={arrowForward}

								/>
							}
						</PopupContent>
					</PopupInner>
				</PopupWrapper>) : null}
		</>
	)
}

export default PopupBottom;