import React from "react";
import { IonCol } from "@ionic/react";
import styled from "styled-components";

const ContainerStyles = styled.div`
    height: 60px;
    width: 279px;
    background-color: transparent;
    border-radius: 50px;
    margin: 30px auto;
    padding: 8px;
    border: 2px solid ${(props) => props.bgcolor};
`;

const FillerStyles = styled.div`
    height: 100%;
    width: ${(props) => props.completed}%;
    background-color: ${(props) => props.bgcolor};
    border-radius: inherit;
    text-align: right;
    transition: width 1s linear;
`;

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;
  
  return (
    <IonCol size="12" className="ion-text-center">
      <ContainerStyles>
        <FillerStyles completed={completed} bgcolor={bgcolor}/>
      </ContainerStyles>
    </IonCol>
  );
};

export default ProgressBar;