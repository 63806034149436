import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin: 10px 10px 22px 20px;
  color: #000;
`;

interface ContainerProps {
  children: string,
 }

const Heading: React.FC<ContainerProps> = ({ children }) => {
  return (
    <StyledHeader>
      {children}
    </StyledHeader>
  );
};

export default Heading;
