import React from 'react';
import { IonContent, IonPage, IonButtons, IonHeader, IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import List from '../components/List'
import Heading from '../components/Heading';
import TutorialProgressBar from '../components/TutorialProgressBar';
import Toolbar from '../components/Toolbar';
import styled from "styled-components";

const PageContent = styled(IonPage)`
	--ion-background-color: white; 
	--ion-text-color: black;
`;

const contentsData = [
	{
		label: "iLet Setup",
		href: "ILetSetup",
		icon: "assets/icon/power.png",
	},
	// {
	// 	label: "High Glucose Alert",
	// 	href: "HighGlucose",
	// 	icon: "assets/icon/icon-alert.png",
	// },
	// {
	// 	label: "Low Glucose Alert",
	// 	href: "LowGlucose",
	// 	icon: "assets/icon/bell.png",
	// }
];

interface ContainerProps {
	currentProgress: number,
	setCurrentProgress: Function,
};


const Scenarios: React.FC<ContainerProps> = ({ currentProgress, setCurrentProgress }) => {

	return (
		<PageContent>
			<IonHeader className='ion-no-border'>
				<Toolbar>
					<IonButtons slot="start">
						<IonButton routerLink="Home" routerDirection="back">
							<IonIcon slot="icon-only" icon={arrowBack} />
						</IonButton>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<IonContent fullscreen>
				<Heading>Scenarios</Heading>
				<TutorialProgressBar num={3} currentProgress={currentProgress} />
				<List
					lines="none"
					data={contentsData}
					ShowGreyScale={true}
					currentProgress={currentProgress}
					setCurrentProgress={setCurrentProgress}
				/>
			</IonContent>
		</PageContent>
	);
};

export default Scenarios;
