import styled from "styled-components";
import { IonRow } from "@ionic/react";
import { SimulatorPage } from "../pages/SimulatorEntry";
import ContextList from '../components/ContextList'
import React, { useState } from 'react';
import Popup from '../components/Popup';
import usePersistedState from "../usePersistedState";



const StyledNavBar = styled(IonRow)`
  margin-top: 8px;
  padding: 3px 16px;
`;

const StyledPText = styled.p<CGMProps>`
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
	margin: ${props => props.Margin};
`;


interface CGMProps {
	setCurrentPage?: (page: SimulatorPage) => void;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
	setIsMenuShown?: Function,
}

const CGM: React.FC<CGMProps> = ({ setCurrentPage , setIsMenuShown}) => {
	const [showSensorPopup, setShowSensorPopup] = useState(false);
	const [showCGMAlertsPopup, setShowCGMAlertsPopup] = useState(false);
	const [showCGMInfoPopup, setShowCGMInfoPopup] = useState(false);
	const [cgmTitle] = usePersistedState('cgmTitle', 'Dexcom G6 CGM'); // update menu label, based on selected cgm sensor

	const contentsData = [
		{
			label: "Start Sensor",
			popup: 'setShowSensorPopup',
			hideIcon: true,
		},
		{
			label: "CGM Alerts",
			popup: 'setShowCGMAlertsPopup',
			hideIcon: true,
		},
		{
			label: "CGM Info",
			popup: 'setShowCGMInfoPopup',
			hideIcon: true,
		},
		// New Menu label added for Switch CGM Sensor
		{
			label: "Switch CGM Sensor",
			goToPage: 'switchCGMSensor',
			borderBottom: true
		}
	]

	return (

		
		<>
			<Popup
				show={showSensorPopup}
				setShow={setShowSensorPopup}
				center={true}
			>
				<p>Here you'll learn how to start a new sensor. If your sensor's still warming up, you'll see a countdown showing you when it'll be ready to roll.</p>
			</Popup>

			<Popup
				show={showCGMAlertsPopup}
				setShow={setShowCGMAlertsPopup}
				center={true}
			>
				<p>Stay on track with alerts. You can set these for high BG (300 mg/dl), low BG (75 mg/dl), urgent low soon (below 54 mg/dl within 20 minutes), and fall rate (if your BG is less than 100 mg/dl and falling 2mg/dl per minute or more).</p>
			</Popup>

			<Popup
				show={showCGMInfoPopup}
				setShow={setShowCGMInfoPopup}
				center={true}
			>
				<p>Here you'll be able to switch out your sensor and transmitter.</p>
			</Popup>


			<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
				<img
					onClick={() => {
							setCurrentPage?.("entry");
							setIsMenuShown?.(true);
						}
					}	
					src={"assets/icon/back-arrow.png"}
					width="auto"
					height="34px"
					alt=""
					
				/>

				<StyledPText
					fontSize="22px"
				>{cgmTitle}
				</StyledPText>

				<img
					onClick={() => { setCurrentPage?.("entry") }}
					className="icon-home"
					src={"assets/icon/icon-home.png"}
					width="40px"
					height="auto"
					alt=""
				/>
			</StyledNavBar>

			<ContextList
				setShowSensorPopup={setShowSensorPopup}
				setShowCGMAlertsPopup={setShowCGMAlertsPopup}
				setShowCGMInfoPopup={setShowCGMInfoPopup}
				marginTop="0"
				margin="0"
				fontSize="18px"
				data={contentsData}
				padding="0"
				borderTop='2px solid white'
				borderBottom='2px solid white'
				setCurrentPage={setCurrentPage}
			/>
		</>
	);
};

export default CGM;
