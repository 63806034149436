import React from 'react';
import styled from "styled-components";
import { IonRow } from "@ionic/react";
import { SimulatorPage } from "../pages/SimulatorEntry";

const StyledNavBar = styled(IonRow)`
  margin-top: 0;
  padding: 3px 16px;
`;

const StyledPText = styled.p<SettingsProps>`
	font-family: "Open Sans";
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
	margin: ${props => props.Margin};
`;

interface SettingsProps {
	setCurrentPage?: (page: SimulatorPage) => void;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
}

const AlgorithmSteps: React.FC<SettingsProps> = ({ setCurrentPage }) => {

	return (
		<>
			<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
				<img
					onClick={() => { setCurrentPage?.("history") }}
					src={"assets/icon/back-arrow.png"}
					width="auto"
					height="34px"
					alt=""
				/>

				<StyledPText
					fontSize="22px"
				>Algorithm Steps
				</StyledPText>

				<img
					onClick={() => { setCurrentPage?.("entry") }}
					className="icon-home"
					src={"assets/icon/icon-home.png"}
					width="40px"
					height="auto"
					alt=""
				/>
			</StyledNavBar>

			<div className="c-algorithm">
				<div className="c-algorithm__step">
					<div className="c-algorithm__date">2022-Jan-01, 12:05 pm</div>
					<div className="c-algorithm__info">
                        <div>- CGM: 115 mg/dL</div>
                        <div>- Insulin: 0.1 u</div>
                        <div>- Req. Insulin: 0.1 u</div>
                    </div>
				</div>
				<div className="c-algorithm__step">
					<div className="c-algorithm__date">2022-Jan-01, 12:00 pm</div>
					<div className="c-algorithm__info">
                        <div>- CGM: 116 mg/dL</div>
                        <div>- Insulin: 6.7 u</div>
                        <div>- Req. Insulin: 6.7 u</div>
                        <div>- Meal Size: More</div>
                    </div>
				</div>
                <div className="c-algorithm__step">
					<div className="c-algorithm__date">2022-Jan-01, 11:55 am</div>
					<div className="c-algorithm__info">
                        <div>- CGM: 110 mg/dL</div>
                        <div>- Insulin: 0.2 u</div>
                        <div>- Req. Insulin: 0.2 u</div>
                    </div>
				</div>
                <div className="c-algorithm__step">
					<div className="c-algorithm__date">2022-Jan-01, 11:50 am</div>
					<div className="c-algorithm__info">
                        <div>- CGM: 112 mg/dL</div>
                        <div>- Insulin: 0.1 u</div>
                        <div>- Req. Insulin: 0.1 u</div>
                    </div>
				</div>
			</div>
		</>
	);
};

export default AlgorithmSteps;
