import { IonRow, IonCol, IonText } from "@ionic/react";
import StatusBar from "../components/StatusBar";
import { Device } from '@capacitor/device';
import Menu from "../components/Menu";
import ILetFrame from "../components/ILetFrame";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from '../components/Button';
import PopupBottom from '../components/PopupBottom';

const StyledNavBar = styled(IonRow)`
  margin-top: 8px;
  padding: 3px 20px;
`;

const StyledPText = styled.p<ContainerProps>`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.Margin};
`;

const StyledRow = styled(IonRow)<ContainerProps>`
	padding: ${props => props.padding};
`;

const StyledGoBionic = styled(IonRow)`
	position: relative;
	height: 240px;
	margin: 30px auto 0 auto;
	max-width: 230px;
	background-image: url(../assets/global/go-bionic-circle.png);
	background-size: 100%;
	background-repeat: no-repeat;
	overflow: hidden;
`;

const StyledImg = styled.img<ContainerProps>`
	margin: ${props => props.Margin};
`;

const PositionElement = styled.div`
	position: relative;
	top: -89px;
    left: -35px;
	pointer-events: none;
`;

const StyledBellIcon = styled.div`

	height: 96px;
	width: 86px;
	position: absolute;
	top: 87px;
	background-repeat: no-repeat;
	left: 240px;
	padding: 11px;
	display: block;
	background-image: url(assets/icon/circle-pulse-animation.gif);
	background-size: 100%;
`;

interface ContainerProps {
	position?: string;
	fontWeight?: string;
	fontSize?: string;
	Margin?: string;
	padding?: string;
	height?: string;
	zIndex?: string;
};

export type LowGlucosePage = "lowGlucoseContent" | "urgentLowSoon" | "goBionic" | "urgentLowGlucose" | "goBionicSecond";

const LowGlucose: React.FC<ContainerProps> = ({padding, height}, props) => {
	const [currentPage, setCurrentPage] = useState<LowGlucosePage>("lowGlucoseContent");
	const [hideCloseMenuButton, setHideCloseMenuButton] = useState(false);
	const [hideStatusBarBellIcon, setHideStatusBarBellIcon] = useState(true);
	const [hideBorderBottom, setHideBorderBottom] = useState(false);
	const [showScenariosPopup, setShowScenariosPopup] = useState(false);
	const [bellWithNotification, setBellWithNotification] = useState(false);
	const [device, setDevice] = useState<string>();

	const platform = async () => {
		const info = await Device.getInfo();
		return info.platform;
	};
	
	useEffect(() => {
		platform()
		.then((pl) => { setDevice(pl) })
		.catch((err) => { return err });
	})
	console.log('📱 Device: ', device);

	let colorSet: string = "";
	switch(device){
		case "web":
			colorSet = "light";
			break;
		case "ios":
			colorSet = "light";
			break;
		case "android":
			colorSet = "dark";
			break;
	};
	

	return (

		<>
			<ILetFrame>
				
			
			{ !hideCloseMenuButton &&
				<Menu hideMenuItems={true} hideAlertItems={true} hideBorderBottom={hideBorderBottom}>
						<img
							src="../assets/icon/icon-close-menu.png"
							width="82px"
							height="auto"
							alt=""
						/>
				</Menu>
			}

				<StatusBar showBellIcon={hideStatusBarBellIcon}></StatusBar>

				{currentPage === 'goBionic' &&
					<PositionElement>
						<StyledBellIcon></StyledBellIcon>
					</PositionElement>
				}

				<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
					
					
					<img
						
						className="icon-menu"
						src="../assets/icon/icon-menu.png"
						width="44px"
						height="auto"
						alt=""
					/>
						
					
					<img
						className="icon-cartridge"
						src="../assets/icon/icon-cartridge.png"
						width="25px"
						height="auto"
						alt=""
					/>

				
						<img
							onClick={() => {
								setHideStatusBarBellIcon(true);
								setHideBorderBottom(false);
								setHideCloseMenuButton(false);
								setCurrentPage('urgentLowGlucose');
								setBellWithNotification(false);
							}}
							className="icon-bell"
							src={bellWithNotification ? "../assets/icon/icon-bell-notification.png" : "../assets/icon/icon-bell.png"}
							width="42px"
							height="auto"
							alt=""
						/>
					
				</StyledNavBar>

				

				{currentPage === "lowGlucoseContent" ? (
					<>
						<StyledRow padding="20px 0 0 16px" className="ion-justify-content-start">
							<img
								src="../assets/icon/icon-arrow-back-small.png"
								width="16px"
								height="26px"
								alt=""
							/>
						</StyledRow>
						<StyledRow padding="20px 0 0 16px" className="ion-justify-content-center">
							<img
								src="../assets/icon/low-glucose.png"
								width="100px"
								height="60px"
								alt=""
							/>
						</StyledRow>

						<IonRow className="ion-justify-content-center">
							<StyledPText Margin="16px 0 10px 0" fontSize="24px"><strong>Low Glucose</strong></StyledPText>
							<StyledPText Margin="0 0 76px 0" fontSize="18px">Glucose is below 75 mg/dL.</StyledPText>
						</IonRow>

						<StyledRow height="176px" className="ion-justify-content-end">
							<Button
								label="Dismiss"
								color={colorSet}
								fill="outline"
								fontSize="20px"
								width="100%"
								padding="0 21px"
								className="addAnimation"
								onClick={() => {
									setCurrentPage('urgentLowSoon');
								}}
							/>
						</StyledRow>
					</>
				) 
				
				: currentPage === "urgentLowSoon" ? (

					<>
						<StyledRow padding="20px 0 0 16px" className="ion-justify-content-start">
							<img
								src="../assets/icon/icon-arrow-back-small.png"
								width="16px"
								height="26px"
								alt=""
							/>
						</StyledRow>
						<IonRow className="ion-justify-content-center">
							<img
								src="../assets/icon/urgent-low-soon.png"
								width="100px"
								height="60px"
								alt=""
							/>
						</IonRow>

						<IonRow className="ion-justify-content-center">
							<StyledPText Margin="16px 0 10px 0" fontSize="24px"><strong>Urgent Low Soon</strong></StyledPText>
							<StyledPText className="ion-text-center" Margin="0 0 53px 0" fontSize="18px">54 mg/dL within 20 min.<br/>Act now to prevent<br/>urgent low.</StyledPText>
						</IonRow>

						<IonRow className="ion-justify-content-center">
							<Button
								label="Dismiss"
								color={colorSet}
								fill="outline"
								fontSize="20px"
								width="100%"
								padding="0 21px"
								className="addAnimation"
								onClick={() => {
									setCurrentPage('goBionic');
									setHideCloseMenuButton(true);
									setHideStatusBarBellIcon(false);
									setHideBorderBottom(true);
									setBellWithNotification(true);
								}}
							/>
						</IonRow>
					</>
					
				)

				: currentPage === "goBionic" ? (
					<>
					<StyledGoBionic
							class="text-center ion-align-items-center go-bionic"
						>
							<IonCol size="12" className="ion-text-center">
								<img
									className="icon-arrow-right-down"
									src="../assets/icon/icon-arrow-right-down.png"
									width="26px"
									height="auto"
									alt=""
								/>
								<IonText>
									<StyledPText
										fontWeight="bold"
										fontSize="36px"
										Margin="4px 0"
										className="mg-value"
									>
										72
									</StyledPText>
								</IonText>
								<IonText>
									<StyledPText fontSize="20px" Margin="0">mg/dL</StyledPText>
								</IonText>
							</IonCol>
						</StyledGoBionic>

						<IonRow>
						
							<IonCol size="12" className="ion-text-center">
								<StyledImg
									Margin="16px 0 0 0"
									className="icon-meal"
									src="../assets/icon/icon-meal.png"
									width="60px"
									height="auto"
									alt=""
								/>
							</IonCol>
							
							
						</IonRow>
					</>
				) 

				: currentPage === "urgentLowGlucose" ? (
					<>
						<StyledRow padding="20px 0 0 16px" className="ion-justify-content-start">
							<img
								src="../assets/icon/icon-arrow-back-small.png"
								width="16px"
								height="26px"
								alt=""
							/>
						</StyledRow>
						<IonRow className="ion-justify-content-center">
							<img
								src="../assets/icon/urgent-low-glucose.png"
								width="100px"
								height="60px"
								alt=""
							/>
						</IonRow>

						<IonRow className="ion-justify-content-center">
							<StyledPText Margin="16px 0 10px 0" fontSize="24px"><strong>Urgent Low Glucose</strong></StyledPText>
							<StyledPText className="ion-text-center" Margin="0 0 75px 0" fontSize="18px">Act now. Glucose is below<br/>54 mg/dL.</StyledPText>
						</IonRow>

						<IonRow className="ion-justify-content-center">
							<Button
								label="Dismiss"
								color={colorSet}
								fill="outline"
								fontSize="20px"
								width="100%"
								padding="0 21px"
								className="addAnimation"
								onClick={() => {
									setHideStatusBarBellIcon(false);
									setHideBorderBottom(true);
									setHideCloseMenuButton(true);
									setCurrentPage('goBionicSecond');
									setTimeout(() => {
										setShowScenariosPopup(true);
									}, 2000);
								}}
							/>
						</IonRow>
					</>
				) 

				: currentPage === "goBionicSecond" ? (
					<>
					<StyledGoBionic
							
							class="text-center ion-align-items-center go-bionic"
						>
							<IonCol size="12" className="ion-text-center">
								<img
									className="icon-arrow-right"
									src="../assets/icon/icon-arrow-right.png"
									width="36px"
									height="auto"
									alt=""
								/>
								<IonText>
									<StyledPText
										fontWeight="600"
										fontSize="36px"
										Margin="4px 0"
										className="mg-value"
									>
										52
									</StyledPText>
								</IonText>
								<IonText>
									<StyledPText fontSize="20px" Margin="0">mg/dL</StyledPText>
								</IonText>
							</IonCol>
						</StyledGoBionic>

						<IonRow>
						
							<IonCol size="12" className="ion-text-center">
								<StyledImg
								
								className="icon-meal"
								src="../assets/icon/icon-meal.png"
								width="60px"
								height="auto"
								alt=""
							/>
							</IonCol>
							
							
						</IonRow>

						<PopupBottom
							show={showScenariosPopup}
							setShow={setShowScenariosPopup}
							label="Back to Scenarios"
							href="scenarios"
							center={true}
							button={true}
							backgroundOverlay={true}
						>
							<p>Head back to explore some<br/>more scenarios.</p>
						</PopupBottom>
					</>
				) 
			
				: null}
			</ILetFrame>

			<p>test</p>
		</>
	);
};

export default LowGlucose;
