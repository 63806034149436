import { IonCol, IonText, IonRow } from "@ionic/react";
import Slider from '../components/Slider';
import React, { useState, useEffect } from "react";
import { SimulatorPage } from "../pages/SimulatorEntry";
import styled from "styled-components";
import Popup from '../components/Popup';

const StyledGoBionic = styled(IonRow)<DeliveringPage>`
	height: 230px;
	margin: 20px auto 0 auto;
	max-width: 230px;
	background-image: url(../assets/global/go-bionic-circle.png);
	background-size: 100%;
	background-repeat: no-repeat;
    overflow: hidden;
    transform: rotate(${(props) => props.deg ? props.deg * 10 : null }deg);
    transition: transform 1s linear;
`;

const StyledNavBar = styled(IonRow)`
	margin-top: 8px;
	padding: 3px 16px;
`;

const HoldingStyle = styled.div`
    position: relative;

    .ion-text-center {
        position: absolute;
        top:60px;
    }
`;

const StyledPText = styled.p<DeliveringPage>`
	font-weight: ${(props) => props.fontWeight};
	font-size: ${(props) => props.fontSize};
    margin: ${(props) => props.Margin};
    text-align: center;
    width: 100%;
`;

interface DeliveringPage {
	currentPage?: string;
    setCurrentPage?: (page: SimulatorPage) => void;
    counterHandler?: Function;
    counter?: number;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
    setIsMenuShown?: Function,
    deg?: number,
}

const Delivering: React.FC<DeliveringPage> = ({ currentPage, setCurrentPage }) => {

	const [showCartridgePopup, setShowCartridgePopup] = useState(false);
    const [unlocked, setUnlocked] = useState(false);

    const [deg, setDeg] = useState(0);
    
    useEffect(() => {
		setTimeout(() => {
			setDeg(deg + 5);
		}, 1000);
		setTimeout(() => {
			setCurrentPage?.("entry");
		}, 7000);
    },[deg, setCurrentPage]);

	return (

		<>
				<Popup
					show={showCartridgePopup}
					setShow={setShowCartridgePopup}
					center={true}
				>
					<p>Keep track of your<br/> remaining units of insulin. <br/><br/> You can change<br/>your infusion set and cartridge here too!</p>
				</Popup>

				{currentPage === "Delivering" ? (
					<>
						<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
                            <img
                                className="icon-menu"
                                src="../assets/icon/icon-lock.png"
                                width="44px"
                                height="auto"
                                alt=""
								/>
							<img
								className="icon-cartridge"
								src="../assets/icon/icon-cartridge.png"
								width="25px"
								height="auto"
								alt=""
							/>
							<img
								className="icon-bell"
								src="../assets/icon/icon-bell.png"
								width="48px"
								height="auto"
								alt=""
							/>
						</StyledNavBar>

                        <HoldingStyle>
                            <StyledGoBionic deg={deg} class="text-center ion-align-items-center go-bionic" />
							<IonCol size="12" className="ion-text-center">
								<img
									className="icon-arrow-right"
									src="../assets/icon/icon-arrow-right.png"
									width="36px"
									height="auto"
									alt=""
								/>
								<IonText>
									<StyledPText
										fontWeight="bold"
										fontSize="36px"
										Margin="4px 0"
										className="mg-value"
									>
										115
									</StyledPText>
								</IonText>
								<IonText>
									<StyledPText fontSize="20px" Margin="0">mg/dL</StyledPText>
								</IonText>
							</IonCol>
                        </HoldingStyle>

                        <IonRow>
                            {/* <StyledPText
                                fontSize="22px"
                                Margin="10px 0 0 0"
                            >Delivery In Progress
                            </StyledPText> */}
                        </IonRow>
						
							{
                            !unlocked ?
                            <IonRow>
                                <IonCol size="12" className="ion-text-center">
                                    <Slider marginTop="6px" label="Unlock" onSlide={() => { setUnlocked(true) }} />
                                </IonCol>
                            </IonRow>
                            :
                            deg < 100 ? 
                            setCurrentPage?.("Starting")
                            :
                            setCurrentPage?.("entry")	
							}
							
						
					</>
				) 
				:null
				

				}
		

			
		</>
	);
};

export default Delivering;