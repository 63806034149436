import React from 'react';
import { IonIcon, IonImg, IonItem, IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { checkmarkCircle } from 'ionicons/icons';
import { Browser } from '@capacitor/browser';


const StyledListItem = styled(IonItem) <ContainerProps>`
	margin: ${props => props.margin || '1rem auto'};
	padding: ${props => props.padding || '0 1rem'};
	border-top: ${props => props.borderTop};
	border-bottom: ${props => props.borderBottom};
	font-size: ${props => props.fontSize};
	--background: #ffffff;
`;

const StyledIonLabel = styled(IonLabel) <ContainerProps>`
	margin: ${props => props.margin || '10px 0'};
	background: #ffffff;
	--ion-background-color: white;
`;

const StyledImg = styled(IonImg) <{ isActive?: boolean }>`
	width: 30px;
	margin: auto 25px auto 0;
	filter: ${props => props.isActive ? 'grayscale(0)' : 'grayscale(1)'};
`;

const StyledImgChevron = styled(IonImg)<StyledImgChevronProps>`
	width: ${props => props.width || '10px'};
	margin-right: ${props => props.marginRight || '1rem'};
`;

const StyledIonIcon = styled(IonIcon)`
	color:rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0.5);
	fill: rgba(0, 0, 0, 0.5);
`;

interface StyledImgChevronProps {
	width?: string,
	marginRight?: string,
};

interface ContainerProps {
	item: { label: string, href?: string, icon: string, popup?: any, externalURL?: string },
	i: number,
	currentProgress: number,
	setCurrentProgress: Function,
	padding?: string,
	margin?: string,
	borderTop?: string,
	borderBottom?: string,
	hideIconEnd?: boolean,
	hideIconStart?: boolean,
	ShowGreyScale?: boolean,
	chevronWhite?: boolean,
	onClick?: any,
	fontSize?: string,
	setShowAboutPopup?: any,
	
};

const ListItem: React.FC<ContainerProps> = ({ 
	setShowAboutPopup, hideIconStart, onClick, hideIconEnd, ShowGreyScale, chevronWhite, padding, 
	fontSize, margin, borderTop, borderBottom, item, i, currentProgress, setCurrentProgress }, props) => {
	const isNext = currentProgress === i;
	const isCompleted = currentProgress > i;
	const isActive = isNext || isCompleted

	// Only updates progress if it is larger: stops progress going backwards
	const setProgressLarger = () => {
		if (i + 1 > currentProgress) {
			setCurrentProgress(i + 1)
		}
		
	};
	


	return (
		<StyledListItem {...props}
			detail={false}
			padding={padding}
			margin={margin}
			fontSize={fontSize}
			borderTop={borderTop}
			borderBottom={borderBottom}
			routerLink={isActive ? item.href : undefined}
			

			onClick={() => {

					if(isActive) {
						setProgressLarger();
					}
					return item.externalURL ? Browser.open({  url: `${item.externalURL}` }) : undefined;
				}
			}


			


		>	
			{!hideIconStart && <StyledImg isActive={ShowGreyScale ? isActive : true} slot="start" src={item.icon} />}
			<StyledIonLabel {...props} margin="17px 0" dangerouslySetInnerHTML={{__html: item.label}}>
			</StyledIonLabel>
			{!isCompleted && !hideIconEnd && !chevronWhite && <StyledImgChevron slot="end" src={'assets/icon/icon-chevron.png'} />}
			{/* {chevronWhite && <StyledImgChevron marginRight="0" width="22px" slot="end" src={'assets/icon/icon-chevron-white.png'} />} */}
			{isCompleted && !hideIconEnd && !chevronWhite && <StyledIonIcon icon={checkmarkCircle} />}
		</StyledListItem>
	);
};

export default ListItem;
