import styled from "styled-components";
import { IonRow } from "@ionic/react";
import { SimulatorPage } from "../pages/SimulatorEntry";
import ContextList from '../components/ContextList'
import React, { useState } from 'react';
import Popup from '../components/Popup';

const StyledNavBar = styled(IonRow)`
  margin-top: 0;
  padding: 3px 16px;
`;

const StyledPText = styled.p<HistoryProps>`
	font-family: "Open Sans";
	font-weight: ${props => props.fontWeight};
	font-size: ${props => props.fontSize};
	margin: ${props => props.Margin};
`;


interface HistoryProps {
	setCurrentPage?: (page: SimulatorPage) => void;
	Margin?: string;
	fontSize?: string;
	fontWeight?: string;
	hideIconStart?: boolean,
	chevronWhite?: boolean,
	setIsMenuShown?: Function,
}

const History: React.FC<HistoryProps> = ({ setCurrentPage, setIsMenuShown }) => {
	const [showAlertsPopup, setShowAlertsPopup] = useState(false);
	const [showMealsPopup, setShowMealsPopup] = useState(false);
	const [showCartridgesPopup, setShowCartridgesPopup] = useState(false);
	const [showInfusionPopup, setShowInfusionPopup] = useState(false);
	const [showAlgorithmPopup, setShowAlgorithmPopup] = useState(false);


	const contentsData = [
		{
			label: "Alerts",
			popup: 'setShowAlertsPopup',
			hideIcon: true,
		},
		{
			label: "Meals",
			popup: 'setShowMealsPopup',
			hideIcon: true,
		},
		{
			label: "Cartridges",
			popup: 'setShowCartridgesPopup',
			hideIcon: true,
		},
		{
			label: "Infusion Sets",
			popup: 'setShowInfusionPopup',
			hideIcon: true,
		},
		{
			label: "Algorithm Steps",
			goToPage: 'algorithmSteps',
			hideIcon: true,
		},
		{
			label: "Insulin History",
			borderBottom: true,
			goToPage: 'backupTherapy',
			hideIcon: true,
		}
	]

	return (

		
		<>

			<Popup
				show={showAlertsPopup}
				setShow={setShowAlertsPopup}
				center={true}
			>
				<p>So you never miss a beat, you can look back at recent alerts with timestamps.</p>
			</Popup>

			<Popup
				show={showMealsPopup}
				setShow={setShowMealsPopup}
				center={true}
			>
				<p>Find a run down of your recent tracked meals including meal type, size, and time.</p>
			</Popup>

			<Popup
				show={showCartridgesPopup}
				setShow={setShowCartridgesPopup}
				center={true}
			>
				<p>Check out your recent cartridge changes and see when and how much insulin was used to fill your infusion set tubing.</p>
			</Popup>

			<Popup
				show={showInfusionPopup}
				setShow={setShowInfusionPopup}
				center={true}
			>
				<p>Check our your recent infusion set changes and see when you last changed, and when you'll need to change again.</p>
			</Popup>

			<Popup
				show={showAlgorithmPopup}
				setShow={setShowAlgorithmPopup}
				center={true}
				>
				<p>The iLet<sup>®</sup> makes dosing decisions as often as every<br/>5 minutes. This is where you can check out your recent insulin dosing and meal announcements. You can dig into details like CGM value and insulin doses, as well as BG value and meal size, if you're interested.</p>
			</Popup>

			<StyledNavBar class="ion-justify-content-between ion-align-items-center nav-bar">
				<img
					onClick={() => {
							setCurrentPage?.("entry");
							setIsMenuShown?.(true);
						}
					}
					src={"assets/icon/back-arrow.png"}
					width="auto"
					height="34px"
					alt=""
				/>

				<StyledPText
					fontSize="22px"
				>History
				</StyledPText>

				<img
					onClick={() => { setCurrentPage?.("entry") }}
					className="icon-home"
					src={"assets/icon/icon-home.png"}
					width="40px"
					height="auto"
					alt=""
				/>
			</StyledNavBar>

			<ContextList
				setShowAlertsPopup={setShowAlertsPopup}
				setShowMealsPopup={setShowMealsPopup}
				setShowCartridgesPopup={setShowCartridgesPopup}
				setShowInfusionPopup={setShowInfusionPopup}
				setShowAlgorithmPopup={setShowAlgorithmPopup}
				marginTop="0"
				margin="0"
				fontSize="16px"
				data={contentsData}
				padding="0"
				borderTop='2px solid white'
				borderBottom='2px solid white'
				setCurrentPage={setCurrentPage}
			/>



		</>
	);
};

export default History;
