import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { IonRow, IonContent } from '@ionic/react';
import { SimulatorPage } from "../pages/SimulatorEntry";
import Button from './Button';
import { Device } from '@capacitor/device';
import usePersistedState from '../usePersistedState';

const StyledNavBar = styled(IonRow)`
  margin-top: 8px;
  padding: 1px;
`;

const StyledPText = styled.p<SettingsProps>`
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  margin: ${props => props.Margin};
`;

interface SettingsProps {
  setCurrentPage?: (page: SimulatorPage) => void;
  Margin?: string;
  fontSize?: string;
  fontWeight?: string;
  hideIconStart?: boolean;
  chevronWhite?: boolean;
}

const SwitchCGMSensor: React.FC<SettingsProps> = ({ setCurrentPage }) => {
  const [selectedRadio, setSelectedRadio] = usePersistedState('selectedRadio', 'dexcomG6');
  const [device, setDevice] = useState<string>();
  const [cgmTitle, setCgmTitle] = usePersistedState('cgmTitle', 'Dexcom G6 CGM');

  const platform = async () => {
    const info = await Device.getInfo();
    return info.platform;
  };

  useEffect(() => {
    platform()
      .then((pl) => { setDevice(pl) })
      .catch((err) => { return err });
  }, []);

  useEffect(() => {
    setCgmTitle(selectedRadio === 'dexcomG6' ? 'Dexcom G6 CGM' : 'Dexcom G7 CGM');
  }, [selectedRadio, setCgmTitle]);

  let colorSet: string = "";
  switch (device) {
    case "web":
      colorSet = "dark";
      break;
    case "ios":
      colorSet = "dark";
      break;
    case "android":
      colorSet = "light";
      break;
  };

  return (
    <>
      <StyledNavBar className="ion-justify-content-between ion-align-items-center nav-bar">
        <img
          onClick={() => { setCurrentPage?.("CGM") }}
          src={"assets/icon/back-arrow.png"}
          width="auto"
          height="38px"
          alt=""
        />

        <StyledPText fontSize="22px">Switch CGM Sensor</StyledPText>

        <img
          onClick={() => { setCurrentPage?.("entry") }}
          className="icon-home"
          src={"assets/icon/icon-home.png"}
          width="42px"
          height="auto"
          alt=""
        />
      </StyledNavBar>

      <IonContent className="ion-padding black-background">
        <div className="custom-radio-group">
          <input type="radio" id="dexcomG6" name="cgmSensor" value="dexcomG6" checked={selectedRadio === 'dexcomG6'} onChange={() => setSelectedRadio('dexcomG6')} />
          <label htmlFor="dexcomG6" className="radio-label">Dexcom G6</label>
        </div>
        <div className="custom-radio-group">
          <input type="radio" id="dexcomG7" name="cgmSensor" value="dexcomG7" checked={selectedRadio === 'dexcomG7'} onChange={() => setSelectedRadio('dexcomG7')} />
          <label htmlFor="dexcomG7" className="radio-label">Dexcom G7</label>
        </div>
        <Button
          className="button-color"
          label="Next"
          color=""
          onClick={() => { setCurrentPage?.("CGM") }}
          margin="0px auto 8px auto"
        />
      </IonContent>
    </>
  );
};

export default SwitchCGMSensor;