import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
// @ts-ignore
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
//import { Device } from '@capacitor/device';
import Home from './pages/Home';
import About from './pages/About';
import Scenarios from './pages/Scenarios';
import SimulatorEntry from './pages/SimulatorEntry';
import ILetSetup from './pages/ILetSetup';
import HighGlucose from './pages/HighGlucose';
import LowGlucose from './pages/LowGlucose';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/main.css';

/* Theme variables */
import './theme/variables.css';
import usePersistedState from './usePersistedState';

setupIonicReact();

const App: React.FC = () => {
  const [currentProgress, setCurrentProgress] = usePersistedState('currentProgress', 0)

  return (
    <DeviceOrientation lockOrientation={'portrait'}>
      <Orientation orientation="portrait">
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/about">
            <About currentProgress={currentProgress} setCurrentProgress={setCurrentProgress} />
          </Route>
          <Route exact path="/Scenarios">
            <Scenarios currentProgress={currentProgress} setCurrentProgress={setCurrentProgress} />
          </Route>
          <Route exact path="/SimulatorEntry">
            <SimulatorEntry />
          </Route>
          <Route exact path="/ILetSetup">
            <ILetSetup />
          </Route>
          <Route exact path="/HighGlucose">
            <HighGlucose />
          </Route>
          <Route exact path="/LowGlucose">
            <LowGlucose />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
    </Orientation>
    </DeviceOrientation>
  );
}

export default App;
