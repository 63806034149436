import React, { useState } from 'react';
import styled from "styled-components";
import { IonContent, IonRow } from '@ionic/react';
import { SimulatorPage } from "../pages/SimulatorEntry";
import Slider from './Slider';
import Popup from './Popup';

// import './TimePicker.css'; 
const StyledNavBar = styled(IonRow)`
  margin-top: 8px;
  padding: 3px 16px;
`;

const StyledPText = styled.p<SettingsProps>`
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  margin: ${props => props.Margin};
`;

interface SettingsProps {
  setCurrentPage?: (page: SimulatorPage) => void;
  Margin?: string;
  fontSize?: string;
  fontWeight?: string;
  hideIconStart?: boolean,
  chevronWhite?: boolean,
  setIsMenuShown?: Function;
}

const PauseInsulin: React.FC<SettingsProps> = ({ setCurrentPage, setIsMenuShown }) => {
  const [showPauseInsulinAlert, setShowPauseInsulinAlert] = useState(false);

  return (
    <>
      <Popup
        show={showPauseInsulinAlert}
        setShow={setShowPauseInsulinAlert}
        center={true}
      >
        <p>This is where you pause insulin delivery for the specified time and can be resumed.</p>
      </Popup>
      <StyledNavBar className="ion-justify-content-between ion-align-items-center nav-bar">
        <img
          onClick={() => {
            setCurrentPage?.("entry");
            setIsMenuShown?.(true); 
          }}
          src={"assets/icon/back-arrow.png"}
          width="auto"
          height="38px"
          alt="Back"
        />

        <StyledPText fontSize="22px">Pause Insulin</StyledPText>

        <img
          onClick={() => { setCurrentPage?.("entry") }}
          className="icon-home"
          src={"assets/icon/icon-home.png"}
          width="42px"
          height="auto"
          alt="Home"
        />
      </StyledNavBar>

      <IonContent className="ion-padding black-background">
        <div className="long-text">Remind me to resume insulin delivery in:</div>


        <img
								className=""
								src="../assets/home/scroll-hr-min.png"
			
								height="auto"
								alt=""
							/>
        {/* Add the TimePicker component here */}

        {!showPauseInsulinAlert && (
          <div className="slider-div">
            <Slider marginTop="20px" label="Pause" onSlide={() => setShowPauseInsulinAlert(true)} />
          </div>
        )}
      </IonContent>
    </>
  );
};

export default PauseInsulin;
