import React from 'react';
import { IonContent, IonPage, IonGrid, IonButtons, IonButton, IonHeader, IonIcon, IonRow } from '@ionic/react';
import { arrowBack, close } from 'ionicons/icons';
import Toolbar from '../components/Toolbar'
import styled from 'styled-components';

const StyledPage = styled(IonPage)<ContainerProps>`
	--ion-background-color: black; 
	--ion-text-color: white;
	height: 100%;
	overflow: hidden;
`;

const StyledContent = styled(IonContent)<ContainerProps>`
	--overflow: hidden;
`;

const ILetFrameStyled = styled.div.attrs({
	className: 'ilet-frame',
	})`
		position: relative;
		margin-bottom: env(safe-area-inset-bottom);
		margin-left: auto;
		margin-right: auto;
		width: 375px;
		background-image: url(../assets/global/ilet-frame.png);
		background-size: 100%;
		padding: 18px 40px 48px 42px;
		height: 535px;
		background-repeat: no-repeat;
		overflow: hidden;
	`;

const StyledIcon = styled(IonIcon)<ContainerProps>`
	color: #ffffff;
`;


interface ContainerProps {
}

const ILetFrame: React.FC<ContainerProps> = ({ children }, props) => {
	return (
		<StyledPage>
			<IonHeader>
				<Toolbar bg="#000000">
					<IonButtons slot="start">
						<IonButton routerLink="Home" routerDirection="back">
							<StyledIcon slot="icon-only" icon={close} />
						</IonButton>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<StyledContent>
				<IonGrid>
					<IonRow>
						<IonButtons slot="start">
							<IonButton routerLink="Home" routerDirection="back">
								<IonIcon slot="icon-only" icon={arrowBack} />
							</IonButton>
						</IonButtons>
					</IonRow>
				</IonGrid>
				<ILetFrameStyled>
					{children}
				</ILetFrameStyled>

			</StyledContent>
		</StyledPage>
	);
};

export default ILetFrame;
