import React from 'react';
import { IonContent, IonPage, IonButtons, IonHeader, IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import List from '../components/List'
import Heading from '../components/Heading';
import TutorialProgressBar from '../components/TutorialProgressBar';
import Toolbar from '../components/Toolbar';
import styled from "styled-components";

const PageContent = styled(IonPage)`
	--ion-background-color: white; 
	--ion-text-color: black;
`;

const contentsData = [
	{
		label: "High level value props",
		externalURL: 'https://www.betabionics.com/',
		icon: "assets/icon/tick.png",
	},
	{
		label: "How the iLet<sup>®</sup> technology works",
		externalURL: 'https://www.betabionics.com/',
		icon: "assets/icon/ilet.png",
	},
	// {
	// 	label: "Glooko<sup>®</sup> integration",
	// 	externalURL: 'https://www.betabionics.com/',
	// 	icon: "assets/icon/glooko.png",
	// },
	{
		label: "Dexcom integration",
		externalURL: 'https://www.betabionics.com/',
		icon: "assets/icon/dexcom.png",
	},
	{
		label: "Training resources",
		externalURL: 'https://www.betabionics.com/',
		icon: "assets/icon/training.png",
	},
	{
		label: "Getting to know Beta Bionics",
		externalURL: 'https://www.betabionics.com/',
		icon: "assets/icon/get-to-know.png",
	},
	{
		label: "FAQ",
		externalURL: 'https://www.betabionics.com/',
		icon: "assets/icon/faq.png",
	}

	
];

interface ContainerProps {
	currentProgress: number,
	setCurrentProgress: Function,
	hideIconEnd?: boolean,
};



const About: React.FC<ContainerProps> = ({ currentProgress, setCurrentProgress, hideIconEnd }) => {

	return (
		<PageContent>
			<IonHeader className='ion-no-border'>
				<Toolbar>
					<IonButtons slot="start">
						<IonButton routerLink="Home" routerDirection="back">
							<IonIcon 
							slot="icon-only" 
							icon={arrowBack} 
							/>
						</IonButton>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<IonContent 
			fullscreen
			color='white'
			>
				<Heading>About</Heading>
				<TutorialProgressBar num={3} currentProgress={3} />
				<List
					lines="none"
					data={contentsData}
					ShowGreyScale={false}
					hideIconEnd={true}
					
					
				/>
			</IonContent>
		</PageContent>
	);
};

export default About;
