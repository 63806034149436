import React, { useState, useEffect } from "react";
import { Device } from '@capacitor/device';
import {
  IonContent,
  IonPage,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import Card from "../components/Card";
import Button from "../components/Button";
import styled from "styled-components";

const CardContent = styled(IonCardContent)`
  padding: 24px;
  width: 100%;
  text-transform: inherit;
`;

const StyledIonCardTitle = styled(IonCardTitle)`
	font-size:30px;
	font-family: 'roc-grotesk', sans-serif;
	font-weight: 700;
	font-style: normal;
`;

const StyledIonGrid = styled(IonGrid)`
	padding: 20px 30px 0 30px;
	margin-top: env(safe-area-inset-top);
	margin-bottom: env(safe-area-inset-bottom);
	margin-left: env(safe-area-inset-left);
	margin-right: env(safe-area-inset-right);
`;
const StyledIonPage = styled(IonPage)`
	
`;

const Home: React.FC = () => {

	const [device, setDevice] = useState<string>();

	const platform = async () => {
		const info = await Device.getInfo();
		return info.platform;
	};
	
	useEffect(() => {
		platform()
		.then((pl) => { setDevice(pl) })
		.catch((err) => { return err });
	})

	let colorSet: string = "";
	switch(device){
		case "web":
			colorSet = "dark";
			break;
		case "ios":
			colorSet = "dark";
			break;
		case "android":
			colorSet = "light";
			break;
	};

	return (
	<StyledIonPage>
		<IonContent fullscreen>
		<StyledIonGrid>
			<IonRow>
			<IonCol size="12">
				<Card
				bg="linear-gradient(180deg, #FEB4AF 0%, #8951FD 100%)"
				height="320px"
				marginBottom="20px"
				justifyContent="flex-end"
				center={
					<IonCardHeader>
					<img
						src="../assets/home/iLet-logo-circle.svg"
						width="140px"
						alt=""
					/>
					</IonCardHeader>
				}
				bottom={
					<CardContent>
					<Button
						label="Go to simulator"
						href="SimulatorEntry"
						color={colorSet}
						icon={arrowForward}
					/>
					</CardContent>
				}
				></Card>
			</IonCol>

			<IonCol size="12" class="ion-align-self-center">
				<Card
				bg="transparent"
				marginBottom="20px"
				height="auto"
				border="2px solid black"
				center={
					<IonCardHeader>
						<StyledIonCardTitle 
						color={colorSet}
						>Scenarios</StyledIonCardTitle>
					</IonCardHeader>
				}
				bottom={
					<CardContent>
					<Button
						label="Start a scenario"
						href="Scenarios"
						color={colorSet}
						icon={arrowForward}
					/>
					</CardContent>
				}
				></Card>
			</IonCol>

			{/* <IonCol size="12" class="ion-align-self-center">
				<Card
				bg="transparent"
				height="auto"
				border="2px solid black"
				center={
					<IonCardHeader>
						<StyledIonCardTitle 
						color={colorSet}
						>About</StyledIonCardTitle>
					</IonCardHeader>
				}
				bottom={
					<CardContent>
					<Button
						label="Get in the know"
						href="About"
						color={colorSet}
						icon={arrowForward}
					/>
					</CardContent>
				}
				></Card>
			</IonCol> */}
			</IonRow>
		</StyledIonGrid>
		</IonContent>
	</StyledIonPage>
	);
};

export default Home;
