import React from 'react';
import { IonList } from '@ionic/react';
import styled from 'styled-components';

import ListItem from '../components/ListItem';

const StyledList = styled(IonList) <ContainerProps>`
	margin-top: ${props => props.marginTop || '2rem'};
	font-size: ${props => props.fontSize};
	--ion-background-color: white;
	background: #ffffff;
`;

interface ContainerProps {
	data: Array<{ label: string, href?: string, icon: string, popup?: any, externalURL?: string }>,
	currentProgress?: any,
	setCurrentProgress?: any,
	lines: 'full' | 'inset' | 'none',
	padding?: string,
	margin?: string,
	borderTop?: string,
	borderBottom?: string,
	marginTop?: string,
	fontSize?: string,
	hideIconEnd?: boolean,
	hideIconStart?: boolean,
	ShowGreyScale?: boolean,
	chevronWhite?: boolean,
	onClick?: any,
	setShowAboutPopup?: any,
};

const List: React.FC<ContainerProps> = ({ 
	setShowAboutPopup, 
	hideIconStart, 
	hideIconEnd,
	ShowGreyScale,
	chevronWhite, 
	fontSize, 
	marginTop, 
	data, 
	lines, 
	currentProgress, 
	setCurrentProgress, 
	padding, 
	margin, 
	borderTop, 
	borderBottom 
}, props) => {
	return (
		<StyledList {...props}
			marginTop={marginTop}
			lines={lines}
			>
			
			{data.map((item, i) =>
				<ListItem
					padding={padding}
					margin={margin}
					borderTop={borderTop}
					borderBottom={borderBottom}
					fontSize={fontSize}
					key={i}
					i={i}
					currentProgress={currentProgress}
					setCurrentProgress={setCurrentProgress}
					item={item}
					hideIconEnd={hideIconEnd}
					hideIconStart={hideIconStart}
					ShowGreyScale={ShowGreyScale}
					chevronWhite={chevronWhite}
					setShowAboutPopup={setShowAboutPopup}
				/>
			)}
		</StyledList>
	);
};

export default List;
